import { useComponentData } from "./Cart.componentService";
import CartView from "./Cart.componentView";

const Cart = () => {
  const { componentData, componentService } = useComponentData();
  return (
    <CartView
      componentData={componentData}
      onClearCartData={componentService.onClearCartData}
      onRemoveItem={componentService.onRemoveItem}
      onUpdateQty={componentService.onUpdateQty}
      onApplyCode={componentService.onApplyCode}
    />
  );
};

export default Cart;
