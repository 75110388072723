import { Address } from "@/types/addresses";
import { billingAddressNickname } from "@ultracommerce/react-storefront/global";
import { useSelector } from "react-redux";

type Props = {
  orderPayment: {
    billingAddress: Address;
  };
};

const BillingAddressDetails = ({ orderPayment }: Props) => {
  let billingNickname = useSelector(billingAddressNickname) as string;

  const billingAddress = orderPayment?.billingAddress;

  if (!billingAddress) return;

  const { name, streetAddress, city, stateCode, postalCode, emailAddress } =
    billingAddress;

  return (
    <>
      <h6 className='h6 fw-bold billing-address-title'>
        Payment Method Billing Address
      </h6>
      <p>
        {billingNickname ? (
          <>
            <em>{billingNickname}</em>
            <br />
          </>
        ) : null}
        <ul className='list-inline'>
          <li>{name}</li>
          <li>{streetAddress}</li>
          <li>{`${city}, ${stateCode} ${postalCode}`}</li>
        </ul>

        <span className='text-truncate text-dark d-block'>
          {emailAddress && emailAddress}
        </span>
      </p>
    </>
  );
};

export { BillingAddressDetails };
