import { RootStateType } from "@/types/state";
import { useSelector } from "react-redux";
import { UserAccount } from "@/types/userAccount";
import { mapAccount } from "../../../utils/mapAccount";

export const useAccountProfile = () => {
  const account = useSelector(
    (state: RootStateType) => state.userReducer
  ) as UserAccount;

  return {
    account: account ? mapAccount(account) : null,
  };
};
