import * as Yup from "yup";

const PHONE_REG_EXP = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

const ERROR_MESSAGES = {
  REQUIRED: "Required",
  AT_MOST: (numberOfChars: number) => `At Most ${numberOfChars} Characters`,
  MUST_BE_VALID: (fieldName: string) => `${fieldName} Must Be Valid`,
};

export const billingAddressSchema = Yup.object().shape({
  streetAddress: Yup.string()
    .required(ERROR_MESSAGES.REQUIRED)
    .max(100, ERROR_MESSAGES.AT_MOST(100)),
  city: Yup.string()
    .required(ERROR_MESSAGES.REQUIRED)
    .max(100, ERROR_MESSAGES.AT_MOST(100)),
  postalCode: Yup.string().required(ERROR_MESSAGES.REQUIRED),
  stateCode: Yup.string().required(ERROR_MESSAGES.REQUIRED),
  accountAddressName: Yup.string().when("saveAddress", {
    is: true,
    then: Yup.string().required(ERROR_MESSAGES.REQUIRED),
    otherwise: Yup.string(),
  }),
  phoneNumber: Yup.string()
    .required(ERROR_MESSAGES.REQUIRED)
    .matches(PHONE_REG_EXP, {
      excludeEmptyString: true,
      message: ERROR_MESSAGES.MUST_BE_VALID("Phone Number"),
    }),
  emailAddress: Yup.string()
    .required(ERROR_MESSAGES.REQUIRED)
    .email(ERROR_MESSAGES.MUST_BE_VALID("Email")),
  name: Yup.string()
    .required(ERROR_MESSAGES.REQUIRED)
    .max(100, ERROR_MESSAGES.AT_MOST(100)),
});
