import { useElementContext } from "@ultracommerce/react-storefront/global";
import { Table, TablePagination } from "../common/Table/Table";
import OrderDetailsComponent from "../OrderDetails/OrderDetails.component";
import { Order } from "@/types/order";
import { useOrderHistoryTable } from "./hooks/useOrderHistoryTable";
import { useOrders } from "./hooks/useOrders";

const OrderHistory = () => {
  const {
    CommonModule: { AccountLayout },
  } = useElementContext();

  const {
    orders,
    pagination,
    searchTerm,
    updatePageIndex,
    updateSearchTerm,
    isLoaded,
  } = useOrders();

  const table = useOrderHistoryTable({
    data: orders,
    pagination: pagination,
    setPageIndex: updatePageIndex,
  });

  return (
    <AccountLayout>
      <div className='bg-white p-4'>
        <h2 className='pt-0'>Orders And Returns</h2>

        <div className='d-flex align-items-center mt-3 w-50 gap-4 justify-content-start mb-4'>
          <label htmlFor='search' className='font-weight-bold h6'>
            Search By PO#
          </label>
          <input
            className='form-control rounded-0 w-50'
            value={searchTerm}
            onChange={({ target: { value } }) => updateSearchTerm(value)}
            type='search'
            id='search'
          />
        </div>

        {!orders?.length && isLoaded ? (
          <div className='alert alert-info mt-4' role='alert'>
            No Orders found
          </div>
        ) : null}

        {orders?.length ? (
          <>
            <Table
              table={table}
              renderExtended={(order: Order) => (
                <OrderDetailsComponent id={order.orderID} />
              )}
            />
            <TablePagination table={table} className='mt-4' />
          </>
        ) : null}
      </div>
    </AccountLayout>
  );
};

export default OrderHistory;
