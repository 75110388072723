type Size = "sm" | "md" | "lg";

const SIZES: Record<Size, string> = {
  sm: "spinner-border-sm",
  md: "spinner-border-md",
  lg: "spinner-border-lg",
};

type Props = {
  size?: Size;
  className?: string;
};

export const Loader = ({ size, className }: Props) => (
  <div className={`d-flex justify-content-center ${className}`}>
    <div className={`spinner-border ${SIZES[size || "md"]}`} role='status'>
      <span className='visually-hidden'>Loading...</span>
    </div>
  </div>
);
