type Props = {
  touched: boolean | undefined;
  error: string | undefined;
};

export const FieldError = ({ touched, error }: Props) => {
  if (!touched || !error) return null;

  return (
    <div>
      <div className='text-danger mt-2'>{error}</div>
    </div>
  );
};
