import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  addNewAccountAndSetAsBillingOnOrderFulfillment,
  addPaymentToOrder,
  getSavedCreditCardMethods,
  useElementContext,
} from "@ultracommerce/react-storefront/global";
import { RootStateType } from "@/types/state";
import { CreditCardDetails } from "./components/CreditCardDetails";
import { PaymentMethod } from "@/types/cart";

type Props = {
  isEdit: boolean;
  method: any;
  fulfillment: any;
  isQuote: boolean;
  orderID: string;
  updateQuote: (quote: any) => any;
  resetPaymentDetailsSelection: () => any;
  poNumber: string;
};

const CreditCardPayment = ({
  isEdit = false,
  method,
  fulfillment,
  isQuote = false,
  orderID,
  updateQuote,
  resetPaymentDetailsSelection,
  poNumber,
}: Props) => {
  const {
    CommonModule: { SwRadioSelect },
  } = useElementContext();
  const paymentMethods = useSelector(
    getSavedCreditCardMethods
  ) as PaymentMethod[];
  const [newOrderPayment, setNewOrderPayment] = useState<string | boolean>(
    false
  );
  const { accountPaymentMethod = { accountPaymentMethodID: "" } } = method;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cartState = useSelector((state: RootStateType) => state.cart);
  useEffect(() => {
    if (!paymentMethods.length)
      // if there is no payment method found for the user , open new payment form
      setNewOrderPayment("new");

    if (isEdit) setNewOrderPayment("edit");
  }, [paymentMethods, isEdit]);

  return (
    <>
      <div className='row mb-3'>
        <div className='col-sm-12'>
          <hr />
          {!!newOrderPayment ? (
            <label className='w-100 h4 pt-0'>
              {t("frontend.checkout.cardInfo")}
            </label>
          ) : (
            <SwRadioSelect
              label={t("frontend.checkout.cardInfo")}
              options={paymentMethods}
              onChange={(value: string): any => {
                if (value === "new") setNewOrderPayment("new");
                else {
                  setNewOrderPayment(false);
                  let params = {
                    accountPaymentMethodID: value,
                    orderPaymentID:
                      cartState.orderPayments.at(0)?.orderPaymentID,
                    newOrderPayment: {
                      purchaseOrderNumber: poNumber,
                    },
                  } as Record<string, any>;
                  if (isQuote) params["orderID"] = orderID;
                  dispatch(
                    addNewAccountAndSetAsBillingOnOrderFulfillment({
                      params,
                      isQuote,
                    }) as any
                  );
                  dispatch(addPaymentToOrder({ params, isQuote }) as any).then(
                    (response: any) => {
                      resetPaymentDetailsSelection();
                      if (
                        response.isSuccess() &&
                        isQuote &&
                        response.success().quote
                      )
                        updateQuote(response.success().quote);
                    }
                  );
                }
              }}
              selectedValue={
                newOrderPayment
                  ? newOrderPayment
                  : cartState?.orderPayments.length === 0
                  ? accountPaymentMethod.accountPaymentMethodID
                  : cartState.orderPayments.at(0)?.accountPaymentMethod
                      ?.accountPaymentMethodID
              }
              //@ts-ignore
              displayNew={true}
            />
          )}

          {!newOrderPayment && (
            <button
              className='btn btn-secondary mt-2'
              onClick={() => {
                setNewOrderPayment("new");
              }}
            >
              {t("frontend.checkout.payment.add")}
            </button>
          )}
        </div>
      </div>
      {newOrderPayment && (
        <CreditCardDetails
          isEdit={newOrderPayment === "edit"}
          fulfillment={fulfillment}
          paymentMethodID={method}
          isQuote={isQuote}
          orderID={orderID}
          onSubmit={(res: any) => {
            if (Object.keys(res.success()?.errors || {}).length === 0) {
              setNewOrderPayment(false);
              resetPaymentDetailsSelection();
            }
          }}
          //@ts-ignore
          setNewOrderPayment={setNewOrderPayment}
          poNumber={poNumber}
        />
      )}
    </>
  );
};

export { CreditCardPayment };
