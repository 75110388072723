type Props = {
  isOpen: boolean;
  closePopup: () => void;
  action: () => void;
  title: string;
  description?: string;
  acceptLabel?: string;
  rejectLabel?: string;
};

export const WarningPopup = ({
  isOpen = false,
  closePopup,
  action,
  title,
  description,
  acceptLabel = "Yes",
  rejectLabel = "No",
}: Props) => {
  if (!isOpen) return null;

  return (
    <>
      <div className='modal-backdrop show'></div>

      <div className='modal show d-block' tabIndex={-1}>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Warning</h5>
              <button
                type='button'
                className='btn-close'
                onClick={closePopup}
              ></button>
            </div>
            <div className='modal-body text-center'>
              <h3 className='pt-0 '>{title}</h3>
              {description ? <p>{description}</p> : null}
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className=' border-0 px-3 w-25 py-2 col'
                onClick={closePopup}
              >
                {rejectLabel}
              </button>
              <button
                type='button'
                className=' border-0 px-3 w-25 py-2 col'
                onClick={() => {
                  action();
                  closePopup();
                }}
              >
                {acceptLabel}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
