import { useTranslation } from "react-i18next";

import CartModel from "./Cart.componentModel";
import {
  CartModule,
  useElementContext,
} from "@ultracommerce/react-storefront/global";
import CartItemList from "../../components/Cart/CartItemList/CartItemList";
import { useToggle } from "../../components/common/hooks/useToggle";
import { WarningPopup } from "../../components/common/WarningPopup/WarningPopup";

type Props = {
  componentData: CartModel;
  onClearCartData: () => void;
  onRemoveItem: (id: string) => void;
  onUpdateQty: (id: string, qty: number) => void;
  onApplyCode: (
    promoCode: string,
    setPromoCode: (code: string) => void
  ) => void;
};

const Cart = ({
  componentData: { isEmptyCart, components, isRemovingItem, showClearCart },
  onClearCartData,
  onRemoveItem,
  onUpdateQty,
  onApplyCode,
}: Props) => {
  const {
    PageModule: { DynamicPage },
  } = useElementContext();
  const { t } = useTranslation();

  const {
    isToggledOn: isOpen,
    toggleOff: closePopup,
    toggleOn: openPopup,
  } = useToggle();

  CartModule.CoreComponents.CartPromoBox = () => <div></div>;
  CartModule.CoreControl.CartItemList = CartItemList;

  return (
    <DynamicPage ignoreLayout={true}>
      <WarningPopup
        action={onClearCartData}
        closePopup={closePopup}
        isOpen={isOpen}
        title='Remove All Products?'
        description='All products will be removed from your cart'
        acceptLabel='Yes, Remove'
        rejectLabel='No, Cancel'
      />
      <div className='container my-5'>
        {isEmptyCart && (
          <div className='text-center p-3'>
            <h3>{t("frontend.cart.empty_cart")}</h3>
          </div>
        )}

        {isRemovingItem && (
          <div className='alert alert-success'>
            {t("frontend.cart.removeCartItem")}
          </div>
        )}

        <div className='row'>
          <div className='col-lg-8 col-md-8 col-sm-12'>
            {components.cartOrderItemList && (
              <CartModule.CoreControl.CartItemList
                {...components.cartOrderItemList}
                onUpdateQty={onUpdateQty}
                onRemoveItem={onRemoveItem}
              />
            )}
            {components.quoteOrderItemsList && (
              <CartModule.CoreControl.CartItemList
                {...components.quoteOrderItemsList}
                onUpdateQty={onUpdateQty}
                onRemoveItem={onRemoveItem}
              />
            )}
            {showClearCart && (
              <div className='white-background'>
                <button className='btn btn-link link-btn' onClick={openPopup}>
                  {t("frontend.cart.clearCart")}
                </button>
              </div>
            )}
          </div>
          <div className='col-lg-4 col-md-4 col-sm-12'>
            {components.cartCheckoutOptions && (
              <CartModule.CoreControl.CartCheckoutOptions
                {...components.cartCheckoutOptions}
              />
            )}
            {components.cartCheckOut && (
              <CartModule.CoreControl.CartCheckOut
                {...components.cartCheckOut}
                onApplyCode={onApplyCode}
              />
            )}
          </div>
        </div>
      </div>
    </DynamicPage>
  );
};

export default Cart;
