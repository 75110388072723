import { useEffect, useState } from "react";
import { useGetOrderDetails } from "@ultracommerce/react-storefront/global";
import { OrderDetailsModel } from "./OrderDetails.componentModel";

export const useComponentData = (id: string) => {
  const [componentData, setComponentData] = useState<null | OrderDetailsModel>(
    null
  );
  // TODO consider using Slatwal API service instead of this hook0
  const [request, setRequest] = useGetOrderDetails();

  useEffect(() => {
    // @ts-ignore
    setRequest((request) => ({
      ...request,
      isFetching: true,
      isLoaded: false,
      makeRequest: true,
      params: {
        orderID: id,
      },
    }));
  }, [id, setRequest]);

  useEffect(() => {
    // @ts-ignore
    const { isFetching, isLoaded, data } = request;
    const isDataReady = !isFetching && isLoaded && !!data;

    if (!isDataReady) return () => {};

    setComponentData(new OrderDetailsModel(data));
  }, [request]);

  return {
    componentData,
  };
};
