import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";

import { useState } from "react";
import { getCountries } from "@ultracommerce/react-storefront/global";
import { RootStateType } from "@/types/state";
import { Address, UserAccountAddress } from "@/types/addresses";

const useCreateOrEditAccountAddress = (path: string) => {
  const dispatch = useDispatch();

  const [shippingAddress, setShippingAddress] = useState<Address | null>(null);

  const accountAddresses: UserAccountAddress[] = useSelector(
    (state: RootStateType) => state.userReducer.accountAddresses
  );
  const countryCodeOptions = useSelector(
    (state: RootStateType) => state.content.countryCodeOptions
  );
  const stateCodeOptions = useSelector(
    (state: RootStateType) => state.content.stateCodeOptions
  );

  const currentShippingAddress = useMemo(() => {
    return accountAddresses.find(({ address }) => address?.addressID === path);
  }, [accountAddresses, path]);

  const isEdit = !!currentShippingAddress;

  useEffect(() => {
    if (!currentShippingAddress) return;

    const {
      accountAddressID,
      accountAddressName,
      address,
      additionalInformation,
    } = currentShippingAddress;

    if (!address) return;

    setShippingAddress({
      ...address,
      accountAddressID,
      accountAddressName,
      additionalInformation,
    });
  }, [dispatch, currentShippingAddress]);

  useEffect(() => {
    if (!countryCodeOptions.length) dispatch<any>(getCountries());
  }, [dispatch, countryCodeOptions]);

  return {
    countryCodeOptions,
    stateCodeOptions,
    isEdit,
    shippingAddress,
  };
};

export { useCreateOrEditAccountAddress };
