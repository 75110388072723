import { Link } from "react-router-dom";
import { Link as LinkType } from "../../common/types/link";

type Props = {
  link: Partial<LinkType>;
};

export const TrackYourOrderLink = ({ link }: Props) => {
  if (!link.url) return <strong>{link.label}</strong>;

  return (
    <Link to={link.url} target='_blank'>
      {link.label}
    </Link>
  );
};
