import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { ChangeEvent, useState } from "react";
import dayjs from "dayjs";
import {
  SlatwalApiService,
  addPaymentToOrder,
  getErrorMessage,
  useCheckoutUtilities,
  useElementContext,
} from "@ultracommerce/react-storefront/global";
import { RootStateType } from "@/types/state";

type Props = {
  isEdit: boolean;
  paymentMethodID: string;
  onSubmit: (payment: any) => any;
  fulfillment: any;
  isQuote: boolean;
  orderID: string;
  poNumber: string;
};

const CREDIT_CARD_REGEX =
  /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;

const CreditCardDetails = ({
  isEdit = false,
  paymentMethodID,
  onSubmit,
  fulfillment,
  isQuote = false,
  orderID,
  poNumber,
}: Props) => {
  const {
    CommonModule: {
      SwSelect,
      Button,
      PaymentAddressSelector,
      TextInput,
      ThreeDSRedirect,
    },
  } = useElementContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { fulfillmentMethod } = fulfillment;
  const { months, years } = useCheckoutUtilities();
  const [paymentMethodErrors, setPaymentMethodErrors] = useState<any>({});
  const [savePaymentMethodToAccount, setSavePaymentMethodToAccount] =
    useState(false);
  const [saveShippingAsBilling, setSaveShippingAsBilling] = useState(false);
  let [redirectUrl, setRedirectUrl] = useState();
  let [redirectPayload, setRedirectPayload] = useState({});
  let [redirectMethod, setRedirectMethod] = useState("");
  const cartState = useSelector((state: RootStateType) => state.cart);
  const [paymentMethod, setPaymentMethod] = useState(() => {
    const state = {
      accountPaymentMethodName: "",
      paymentMethodType: "creditCard",
      creditCardNumber: "",
      nameOnCreditCard: "",
      expirationMonth: dayjs().add(1, "month").format("MM"),
      expirationYear: dayjs().add(1, "month").format("YYYY"),
      securityCode: "",
      accountAddressID: "",
      saveShippingAsBilling: false,
      savePaymentMethodToAccount: false,
      returnJSONObjects: "cart",
    };
    if (isEdit && !!cartState.orderPayments?.length) {
      const latestedPayment = cartState.orderPayments?.at(
        cartState.orderPayments.length - 1
      );
      state.nameOnCreditCard = latestedPayment?.nameOnCreditCard;
      state.expirationMonth = latestedPayment?.expirationMonth;
      state.expirationYear = latestedPayment?.expirationYear;
    }
    return state;
  });

  let validCreditCard =
    paymentMethod.nameOnCreditCard.length > 0 &&
    paymentMethod.creditCardNumber.length > 0 &&
    paymentMethod.securityCode.length > 0 &&
    CREDIT_CARD_REGEX.test(paymentMethod.creditCardNumber);

  const addPayment = (params: Record<string, any> = {}) => {
    params = {
      ...params,
      transactionInitiator: "CHECKOUT_PAYMENT",
    };
    if (isQuote) params["orderID"] = orderID;
    return dispatch(
      addPaymentToOrder({
        params,
        isQuote,
      }) as any
    )
      .then((response: any) => {
        if (
          response.isSuccess() &&
          Object.keys(response.success()?.errors || {}).length
        )
          toast.error(getErrorMessage(response.success().errors));
        if (
          response.isSuccess() &&
          Object.keys(response.success()?.errors || {}).length === 0
        ) {
          if (response.success()?.redirectUrl?.length) {
            setRedirectUrl(response.success().redirectUrl);
            setRedirectPayload(response.success().redirectPayload);
            setRedirectMethod(response.success().redirectMethod);
          }
        } else {
          toast.error("An Error Occured");
        }
        return response;
      })
      .then((response: any) => {
        onSubmit(response);
        return response;
      });
  };
  //http://slatwallPrivate:8906/index.cfm/api/scope/pay360threeDSHandover?MD=10145770911&reload=true

  const requiredValidation = ({ value, name, msg }: any) => {
    Yup.string()
      .required(msg)
      .validate(value, { abortEarly: false })
      .then(() => {
        let newErrors = { ...paymentMethodErrors } as Record<string, any>;
        delete newErrors[name];
        setPaymentMethodErrors(newErrors);
      })
      .catch((err) => {
        setPaymentMethodErrors(
          err.inner.reduce((acc: any, { message }: any) => {
            return {
              ...acc,
              [name]: { path: name, message },
            };
          }, paymentMethodErrors)
        );
      });
  };
  if (redirectUrl)
    return (
      <ThreeDSRedirect
        url={redirectUrl}
        payload={redirectPayload}
        method={redirectMethod}
      />
    );

  return (
    <>
      <div className='row mb-3'>
        <div className='col-sm-12'>
          <div className='row'>
            <div className='col-sm-6'>
              <TextInput
                name={paymentMethod.accountPaymentMethodName}
                label={t("frontend.account.payment_method.nickname")}
                value={paymentMethod.accountPaymentMethodName}
                isError={!!paymentMethodErrors?.accountPaymentMethodName}
                errorMessage={
                  paymentMethodErrors?.accountPaymentMethodName?.message
                }
                onChange={(value: string) => {
                  setPaymentMethod({
                    ...paymentMethod,
                    accountPaymentMethodName: value,
                  });
                }}
                onBlur={(value: string) =>
                  requiredValidation({
                    value,
                    name: "accountPaymentMethodName",
                    msg: t("frontend.account.payment_method.required"),
                  })
                }
              />
            </div>

            <div className='col-sm-6'>
              <TextInput
                name={paymentMethod.nameOnCreditCard}
                label={t("frontend.account.payment_method.name")}
                value={paymentMethod.nameOnCreditCard}
                isError={!!paymentMethodErrors?.nameOnCreditCard}
                errorMessage={paymentMethodErrors?.nameOnCreditCard?.message}
                onChange={(value: string) => {
                  setPaymentMethod({
                    ...paymentMethod,
                    nameOnCreditCard: value,
                  });
                }}
                onBlur={(value: string) =>
                  requiredValidation({
                    value,
                    name: "nameOnCreditCard",
                    msg: t("frontend.account.payment_method.name_required"),
                  })
                }
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-5'>
              <TextInput
                name={paymentMethod.creditCardNumber}
                label={t("frontend.account.payment_method.ccn")}
                value={paymentMethod.creditCardNumber}
                isError={!!paymentMethodErrors?.creditCardNumber}
                errorMessage={"Must be a valid credit card"}
                onChange={(value: string) => {
                  if (
                    (/^-?\d+$/.test(value) && value.length < 19) ||
                    value === ""
                  ) {
                    setPaymentMethod({
                      ...paymentMethod,
                      creditCardNumber: value,
                    });
                  }
                }}
                onBlur={(value: string) => {
                  Yup.string()
                    .min(13)
                    .max(19)
                    .matches(CREDIT_CARD_REGEX)
                    .validate(value, { abortEarly: false })
                    .then(() => {
                      let newErrors = { ...paymentMethodErrors };
                      delete newErrors.creditCardNumber;
                      setPaymentMethodErrors(newErrors);
                    })
                    .catch((err) => {
                      setPaymentMethodErrors(
                        err.inner.reduce((acc: any, { message }: any) => {
                          return {
                            ...acc,
                            creditCardNumber: {
                              path: "creditCardNumber",
                              message,
                            },
                          };
                        }, paymentMethodErrors)
                      );
                    });
                }}
              />
            </div>
            <div className='col-sm-2'>
              <TextInput
                name={paymentMethod.securityCode}
                label={t("frontend.account.payment_method.cvv")}
                value={paymentMethod.securityCode}
                isError={!!paymentMethodErrors?.securityCode}
                errorMessage={paymentMethodErrors?.securityCode?.message}
                onChange={(value: string) => {
                  if (
                    (/^-?\d+$/.test(value) && value.length < 5) ||
                    value === ""
                  ) {
                    setPaymentMethod({
                      ...paymentMethod,
                      securityCode: value,
                    });
                  }
                }}
                onBlur={(value: string) => {
                  Yup.string()
                    .min(3)
                    .max(4)
                    .validate(value, { abortEarly: false })
                    .then(() => {
                      let newErrors = { ...paymentMethodErrors };
                      delete newErrors.securityCode;
                      setPaymentMethodErrors(newErrors);
                    })
                    .catch((err) => {
                      setPaymentMethodErrors(
                        err.inner.reduce((acc: any, { message }: any) => {
                          return {
                            ...acc,
                            securityCode: { path: "securityCode", message },
                          };
                        }, paymentMethodErrors)
                      );
                    });
                }}
              />
            </div>
            <div className='col-sm-3'>
              <div className='form-group'>
                <label htmlFor='expirationMonth'>
                  {t("frontend.account.payment_method.expiration_month")}
                </label>
                <SwSelect
                  id='expirationMonth'
                  value={paymentMethod.expirationMonth}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    setPaymentMethod({
                      ...paymentMethod,
                      expirationMonth: e.target.value,
                    });
                  }}
                  options={months}
                />
              </div>
            </div>
            <div className='col-sm-2'>
              <div className='form-group'>
                <label htmlFor='expirationYear'>
                  {t("frontend.account.payment_method.expiration_year")}
                </label>
                <SwSelect
                  id='expirationYear'
                  value={paymentMethod.expirationYear}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    setPaymentMethod({
                      ...paymentMethod,
                      expirationYear: e.target.value,
                    });
                  }}
                  options={years}
                />
              </div>
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col-sm-12'>
              <div className='row'>
                <div className='col-sm-6'>
                  {fulfillmentMethod.fulfillmentMethodType === "shipping" && (
                    <div className='custom-control custom-checkbox'>
                      <input
                        className='custom-control-input'
                        type='checkbox'
                        id='saveShippingAsBilling'
                        checked={saveShippingAsBilling}
                        onChange={(e) => {
                          setSaveShippingAsBilling(!saveShippingAsBilling);
                        }}
                      />
                      <label
                        className='custom-control-label ms-1'
                        htmlFor='saveShippingAsBilling'
                      >
                        {t("frontend.checkout.shipping_address_clone")}
                      </label>
                    </div>
                  )}

                  <div className='custom-control custom-checkbox savePaymentMethodCheckbox'>
                    <input
                      className='custom-control-input'
                      type='checkbox'
                      id='savePaymentMethodToAccount'
                      checked={savePaymentMethodToAccount}
                      onChange={(e) => {
                        setSavePaymentMethodToAccount(
                          !savePaymentMethodToAccount
                        );
                      }}
                    />
                    <label
                      className='custom-control-label ms-1'
                      htmlFor='savePaymentMethodToAccount'
                    >
                      {t("frontend.checkout.payment.save_to_account")}
                    </label>
                  </div>
                </div>
                <div className='col-12 mt-3'>
                  {saveShippingAsBilling && validCreditCard && (
                    <Button
                      label='Submit'
                      onClick={(e) => {
                        e.preventDefault();
                        //TODO: BROKEN
                        if (
                          savePaymentMethodToAccount &&
                          saveShippingAsBilling
                        ) {
                          // Create account Payment and use cloned shpiing address
                          // Payment with Account  CC
                          addPayment({
                            newOrderPayment: {
                              saveShippingAsBilling: 1,
                              nameOnCreditCard: paymentMethod.nameOnCreditCard,
                              creditCardNumber: paymentMethod.creditCardNumber,
                              expirationMonth: paymentMethod.expirationMonth,
                              expirationYear: paymentMethod.expirationYear,
                              securityCode: paymentMethod.securityCode,
                              purchaseOrderNumber: poNumber,
                              paymentMethod: {
                                paymentMethodID,
                              },
                            },
                            saveAccountPaymentMethodName:
                              paymentMethod.accountPaymentMethodName,
                            saveAccountPaymentMethodFlag:
                              savePaymentMethodToAccount,
                          });
                        } else {
                          // Payment with Single use CC and address cloned from billing
                          addPayment({
                            newOrderPayment: {
                              saveShippingAsBilling: 1,
                              nameOnCreditCard: paymentMethod.nameOnCreditCard,
                              creditCardNumber: paymentMethod.creditCardNumber,
                              expirationMonth: paymentMethod.expirationMonth,
                              expirationYear: paymentMethod.expirationYear,
                              securityCode: paymentMethod.securityCode,
                              purchaseOrderNumber: poNumber,
                              paymentMethod: {
                                paymentMethodID,
                              },
                            },
                          });
                        }
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {validCreditCard && !saveShippingAsBilling && (
        <div className='row mb-3'>
          <div className='col-sm-12'>
            {!saveShippingAsBilling && (
              <PaymentAddressSelector
                isEdit={isEdit}
                addressTitle={"Billing Address"}
                selectedAccountID={
                  cartState.orderPayments?.at(0)?.billingAccountAddress
                    ? paymentMethod.accountAddressID
                    : cartState.orderPayments?.at(0)?.billingAccountAddress
                        ?.accountAddressID
                }
                onSelect={(value: string) => {
                  // NOTE: Works
                  addPayment({
                    newOrderPayment: {
                      accountAddressID: value,
                      nameOnCreditCard: paymentMethod.nameOnCreditCard,
                      creditCardNumber: paymentMethod.creditCardNumber,
                      expirationMonth: paymentMethod.expirationMonth,
                      expirationYear: paymentMethod.expirationYear,
                      securityCode: paymentMethod.securityCode,
                      purchaseOrderNumber: poNumber,
                      paymentMethod: {
                        paymentMethodID,
                      },
                    },
                    accountAddressID: value,
                    saveAccountPaymentMethodName:
                      paymentMethod.accountPaymentMethodName,
                    saveAccountPaymentMethodFlag: savePaymentMethodToAccount,
                  });
                }}
                onSave={(values: any) => {
                  if (savePaymentMethodToAccount && values.saveAddress) {
                    // Create account address
                    // Create account Payment
                    // Payment with new Account Address and new Account Payment Method

                    const payload = {
                      name: values.name,
                      streetAddress: values.streetAddress,
                      street2Address: values.street2Address,
                      city: values.city,
                      statecode: values.stateCode,
                      postalcode: values.postalCode,
                      countrycode: values.countryCode,
                      emailAddress: values.emailAddress,
                      phoneNumber: values.phoneNumber,
                      returnJSONObjects: "account",
                    };

                    SlatwalApiService.cart
                      .addEditAccountAndSetAsBillingAddress(payload)
                      .then((response: any) => {
                        if (
                          response.isSuccess() &&
                          Object.keys(response.success()?.errors || {}).length
                        )
                          toast.error(
                            getErrorMessage(response.success().errors)
                          );
                        if (
                          response.isSuccess() &&
                          Object.keys(response.success()?.errors || {})
                            .length === 0
                        ) {
                          addPayment({
                            newOrderPayment: {
                              nameOnCreditCard: paymentMethod.nameOnCreditCard,
                              creditCardNumber: paymentMethod.creditCardNumber,
                              expirationMonth: paymentMethod.expirationMonth,
                              expirationYear: paymentMethod.expirationYear,
                              securityCode: paymentMethod.securityCode,
                              purchaseOrderNumber: poNumber,
                              paymentMethod: {
                                paymentMethodID,
                              },
                            },
                            saveAccountPaymentMethodName:
                              paymentMethod.accountPaymentMethodName,
                            saveAccountPaymentMethodFlag:
                              savePaymentMethodToAccount,
                          });
                        }
                      });
                  } else if (values.saveAddress) {
                    // Create account address
                    // Payment with new Account Address and Single use CC
                    const payload = {
                      name: values.name,
                      streetAddress: values.streetAddress,
                      street2Address: values.street2Address,
                      city: values.city,
                      statecode: values.stateCode,
                      postalcode: values.postalCode,
                      countrycode: values.countryCode,
                      emailAddress: values.emailAddress,
                      phoneNumber: values.phoneNumber,
                      returnJSONObjects: "account",
                    };

                    SlatwalApiService.cart
                      .addEditAccountAndSetAsBillingAddress(payload)
                      .then((response: any) => {
                        if (
                          response.isSuccess() &&
                          Object.keys(response.success()?.errors || {}).length
                        )
                          toast.error(
                            getErrorMessage(response.success().errors)
                          );
                        if (
                          response.isSuccess() &&
                          Object.keys(response.success()?.errors || {})
                            .length === 0
                        ) {
                          addPayment({
                            newOrderPayment: {
                              nameOnCreditCard: paymentMethod.nameOnCreditCard,
                              creditCardNumber: paymentMethod.creditCardNumber,
                              expirationMonth: paymentMethod.expirationMonth,
                              expirationYear: paymentMethod.expirationYear,
                              securityCode: paymentMethod.securityCode,
                              purchaseOrderNumber: poNumber,
                              paymentMethod: {
                                paymentMethodID,
                              },
                            },
                          });
                        }
                      });
                  } else {
                    // and payment with new single use CC and Single use address
                    addPayment({
                      newOrderPayment: {
                        billingAddress: {
                          name: values.name,
                          streetAddress: values.streetAddress,
                          street2Address: values.street2Address,
                          city: values.city,
                          statecode: values.stateCode,
                          postalcode: values.postalCode,
                          countrycode: values.countryCode,
                          emailAddress: values.emailAddress,
                          phoneNumber: values.phoneNumber,
                        },
                        purchaseOrderNumber: poNumber,
                        nameOnCreditCard: paymentMethod.nameOnCreditCard,
                        creditCardNumber: paymentMethod.creditCardNumber,
                        expirationMonth: paymentMethod.expirationMonth,
                        expirationYear: paymentMethod.expirationYear,
                        securityCode: paymentMethod.securityCode,
                        paymentMethod: {
                          paymentMethodID,
                        },
                      },
                    });
                  }
                }}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export { CreditCardDetails };
