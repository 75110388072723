import { useElementContext } from "@ultracommerce/react-storefront/global";
import { t } from "i18next";
import { CreateOrEditAccountAddressModel } from "./CreateOrEditAccountAddress.componentModel";
import { Formik, Form, Field, FormikErrors } from "formik";
import { WarningPopup } from "../../../common/WarningPopup/WarningPopup";
import { Address, CountryCodeOption } from "@/types/addresses";
import { useToggle } from "../../../common/hooks/useToggle";
import { Link } from "react-router-dom";
import { shippingAddressSchema } from "./yup/schema";
import { FieldError } from "../../../common/FieldError/FieldError";
import { LABELS, getLabel } from "../../../common/utils/labels";

type Props = {
  componentData: CreateOrEditAccountAddressModel;
  onAddNewAccountAddress: (address: Address) => void;
  onUpdateAccountAddress: (address: Address) => void;
  onDeleteAccountAddress: () => void;
  getCountryStates: (country: string) => void;
};

export const CreateOrEditAccountAddressComponentView = ({
  componentData: {
    shippingAddress,
    heading,
    isEdit,
    countryCodeDropdown,
    stateCodeDropdown,
    defaultAddress,
    isFormDataReady,
  },
  onAddNewAccountAddress,
  onUpdateAccountAddress,
  onDeleteAccountAddress,
  getCountryStates,
}: Props) => {
  const {
    CommonModule: { AccountContent, AccountLayout },
  } = useElementContext();

  const {
    toggleOff: closeDeleteAccountPopup,
    isToggledOn: isDeleteAccountPopupOpen,
    toggleOn: openDeleteAccountPopup,
  } = useToggle();

  if (!shippingAddress && isEdit) return null;

  const handleOnCountryChange = (
    country: string,
    setFieldValue: (
      field: string,
      value: string,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<Address>>
  ) => {
    getCountryStates(country);
    setFieldValue("countryCode", country);
    setFieldValue("stateCode", "");
  };

  if (!isFormDataReady) return null;

  return (
    <AccountLayout>
      {isEdit ? (
        <WarningPopup
          closePopup={closeDeleteAccountPopup}
          action={onDeleteAccountAddress}
          isOpen={isDeleteAccountPopupOpen}
          title='Are you sure you want to delete this account address?'
        />
      ) : null}
      <AccountContent />
      <div className='bg-light border rounded p-3'>
        <div className='d-flex justify-content-between align-items-center mb-3'>
          <h3 className='font-weight-bold pt-0'>
            {isEdit ? "Edit Shipping Address" : "Add New Shipping Address"}
          </h3>
          {isEdit ? (
            <button
              className='btn btn-outline-danger rounded-0'
              type='button'
              onClick={openDeleteAccountPopup}
            >
              Delete
            </button>
          ) : null}
        </div>
        <Formik
          initialValues={shippingAddress ?? defaultAddress}
          validationSchema={shippingAddressSchema}
          onSubmit={(shippingAddress) => {
            if (isEdit) {
              onUpdateAccountAddress(shippingAddress);
            } else {
              onAddNewAccountAddress(shippingAddress);
            }
          }}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form>
              {heading && <h2>{heading}</h2>}

              {/*company --- */}
              <div className='form-group'>
                <label htmlFor='company' className='fs-6'>
                  {t("frontend.account.company") as string}
                </label>
                <Field name='company' className='form-control' id='company' />
                <FieldError
                  error={errors["company"]}
                  touched={touched["company"]}
                />
              </div>

              {/*firstName and lastName ---*/}
              <div className='row mt-3'>
                <div className='col-md-6 d-flex flex-direction-column'>
                  <label htmlFor='firstName' className='fs-6'>
                    First Name <span className='text-danger h6'>*</span>
                  </label>
                  <Field
                    name='firstName'
                    className='form-control'
                    id='firstName'
                  />
                  <FieldError
                    error={errors["firstName"]}
                    touched={touched["firstName"]}
                  />
                </div>
                <div className='col-md-6 d-flex flex-direction-column'>
                  <label htmlFor='lastName' className='fs-6'>
                    Last Name <span className='text-danger h6'>*</span>
                  </label>
                  <Field
                    name='lastName'
                    className='form-control'
                    id='lastName'
                  />
                  <FieldError
                    error={errors["lastName"]}
                    touched={touched["lastName"]}
                  />
                </div>
              </div>

              {/*country code ---*/}
              <div className='form-group mt-3'>
                <label htmlFor='countryCode' className='fs-6'>
                  {t("frontend.account.countryCode") as string}
                </label>
                <Field
                  id='countryCode'
                  name='countryCode'
                  as='select'
                  className='form-select'
                  onChange={({
                    target: { value },
                  }: React.ChangeEvent<HTMLSelectElement>) => {
                    handleOnCountryChange(value, setFieldValue);
                  }}
                >
                  {countryCodeDropdown.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.name}
                    </option>
                  ))}
                </Field>
                <FieldError
                  error={errors["countryCode"]}
                  touched={touched["countryCode"]}
                />
              </div>

              {/*street address --- */}
              <div className='mt-3 d-flex flex-direction-column'>
                <label htmlFor='streetAddress' className='fs-6'>
                  {t("frontend.account.streetAddress") as string}{" "}
                  <span className='text-danger h6'>*</span>
                </label>
                <Field
                  name='streetAddress'
                  className='form-control'
                  id='streetAddress'
                />
                <FieldError
                  error={errors["streetAddress"]}
                  touched={touched["streetAddress"]}
                />
              </div>

              {/*street address 2 --- */}
              <div className='mt-3 d-flex flex-direction-column'>
                <label htmlFor='street2Address' className='fs-6'>
                  {t("frontend.account.street2Address") as string}
                </label>
                <Field
                  name='street2Address'
                  className='form-control'
                  id='street2Address'
                />
                <FieldError
                  error={errors["street2Address"]}
                  touched={touched["street2Address"]}
                />
              </div>

              {/*city --- */}
              <div className='mt-3 d-flex flex-direction-column'>
                <label htmlFor='city' className='fs-6'>
                  {t("frontend.account.city") as string}{" "}
                  <span className='text-danger h6'>*</span>
                </label>
                <Field name='city' className='form-control' id='city' />
                <FieldError error={errors["city"]} touched={touched["city"]} />
              </div>

              {/*state code options */}
              <div className='row mt-3'>
                <div className='col-md-6'>
                  {stateCodeDropdown &&
                  stateCodeDropdown[values?.countryCode]?.length > 0 ? (
                    <div className='form-dropdown'>
                      <label htmlFor='stateCode' className='fs-6'>
                        {getLabel(values.countryCode, "state")}

                        <span className='text-danger h6'>*</span>
                      </label>
                      <Field
                        id='stateCode'
                        as='select'
                        name='stateCode'
                        options={stateCodeDropdown[values.countryCode]}
                        className='form-select'
                      >
                        {stateCodeDropdown[values.countryCode].map(
                          (option: CountryCodeOption) => (
                            <option key={option.value} value={option.value}>
                              {option.name}
                            </option>
                          )
                        )}
                      </Field>
                    </div>
                  ) : (
                    <div className='form-group'>
                      <label htmlFor='stateCode' className='fs-6'>
                        {getLabel(values.countryCode, "state")}
                        <span className='text-danger h6'>*</span>
                      </label>
                      <Field
                        name='stateCode'
                        className='form-control'
                        id='stateCode'
                      />
                    </div>
                  )}

                  <FieldError
                    error={errors["stateCode"]}
                    touched={touched["stateCode"]}
                  />
                </div>

                {/*zip code --- */}
                <div className='col-md-6 d-flex flex-direction-column'>
                  <label htmlFor='stateCode' className='fs-6'>
                    {getLabel(values.countryCode, "zipCode")}
                    <span className='text-danger h6'>*</span>
                  </label>
                  <Field
                    name='postalCode'
                    label='Zip Code'
                    className='form-control'
                    id='stateCode'
                  />
                  <FieldError
                    error={errors["postalCode"]}
                    touched={touched["postalCode"]}
                  />
                </div>
              </div>

              {/*phone number --- */}
              <div className='mt-3 d-flex flex-direction-column'>
                <label htmlFor='stateCode' className='fs-6'>
                  {t("frontend.account.phoneNumber") as string}{" "}
                  <span className='text-danger h6'>*</span>
                </label>
                <Field
                  name='phoneNumber'
                  className='form-control'
                  id='stateCode'
                />
                <FieldError
                  error={errors["phoneNumber"]}
                  touched={touched["phoneNumber"]}
                />
              </div>

              {/*email address --- */}
              <div className='mt-3 d-flex flex-direction-column'>
                <label htmlFor='emailAddress' className='fs-6'>
                  {t("frontend.account.emailAddress") as string}{" "}
                  <span className='text-danger h6'>*</span>
                </label>
                <Field
                  name='emailAddress'
                  type='email'
                  className='form-control'
                  id='emailAddress'
                />
                <FieldError
                  error={errors["emailAddress"]}
                  touched={touched["emailAddress"]}
                />
              </div>

              {/*account address name --- */}
              <div className='col-md-6 mt-3 d-flex flex-direction-column'>
                <label htmlFor='accountAddressName' className='fs-6'>
                  Nickname (for future reference){" "}
                  <span className='text-danger h6'>*</span>
                </label>
                <Field
                  name='accountAddressName'
                  className='form-control'
                  id='accountAddressName'
                />
                <FieldError
                  error={errors["accountAddressName"]}
                  touched={touched["accountAddressName"]}
                />
              </div>

              {/*Additional information --- */}
              <div className='col-md-6 mt-3 d-flex flex-direction-column'>
                <label htmlFor='additionalInformation' className='fs-6'>
                  Additional Information
                </label>
                <Field
                  name='additionalInformation'
                  className='form-control'
                  as='textarea'
                  id='additionalInformation'
                />
                <FieldError
                  error={errors["additionalInformation"]}
                  touched={touched["additionalInformation"]}
                />
              </div>

              {/*buttons --- */}
              <div className='col-12 mt-4'>
                <div className='d-flex justify-content-center gap-4'>
                  <Link
                    className='btn btn-secondary rounded w-100'
                    type='button'
                    to={"/my-account/profile"}
                  >
                    Cancel
                  </Link>
                  <button
                    className='w-100 btn btn-primary rounded'
                    type='submit'
                  >
                    Save
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </AccountLayout>
  );
};
