import { Loader } from "../common/Loader/Loader";
import { useComponentData } from "./OrderDetails.componentService";
import { OrderDetailsComponentView } from "./OrderDetails.componentView";

const OrderDetailsComponent = ({ id }: { id: string }) => {
  const { componentData } = useComponentData(id);

  return componentData ? (
    <OrderDetailsComponentView componentData={componentData} />
  ) : (
    <Loader className='py-5' />
  );
};

export default OrderDetailsComponent;
