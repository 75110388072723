import { Field, Form, Formik, FormikErrors } from "formik";
import { useTranslation } from "react-i18next";
import { billingAddressSchema } from "./yup/schema";
import { FieldError } from "../../../common/FieldError/FieldError";
import { useDispatch } from "react-redux";
import { getStateCodeOptionsByCountryCode } from "@ultracommerce/react-storefront/global";
import { getLabel } from "../../../common/utils/labels";

type Props = {
  countries: Record<string, Record<string, string>>;
  states: Record<string, { name: string; value: string }[]>;
  defaultContry: string;
  defaultState: string;
  onSave: (values: any) => void;
  onCancel: () => void;
};

export const BillingAddressForm = ({
  countries,
  states,
  onCancel,
  onSave,
  defaultContry,
  defaultState,
}: Props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleOnCountryChange = (
    country: string,
    setFieldValue: (
      field: string,
      value: string,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<any>>
  ) => {
    dispatch<any>(getStateCodeOptionsByCountryCode(country));
    setFieldValue("countryCode", country);
    setFieldValue("stateCode", "");
  };

  return (
    <Formik
      initialValues={{
        name: "",
        streetAddress: "",
        street2Address: "",
        city: "",
        postalCode: "",
        emailAddress: "",
        phoneNumber: "",
        countryCode: defaultContry || "",
        stateCode: defaultState,
        saveAddress: false,
        accountAddressName: "",
      }}
      validationSchema={billingAddressSchema}
      onSubmit={(values) => onSave(values)}
    >
      {({ touched, errors, values, setFieldValue }) => (
        <Form>
          <div className='row mt-3'>
            <div className='col-sm-6'>
              <div className='form-group'>
                <label htmlFor='name'>{t("frontend.account.name")}</label>
                <Field
                  className='form-control'
                  type='text'
                  id='name'
                  name='name'
                />
                <FieldError error={errors["name"]} touched={touched["name"]} />
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='form-group'>
                <label htmlFor='checkout-country'>
                  {t("frontend.account.countryCode")}
                </label>
                <Field
                  id='countryCode'
                  name='countryCode'
                  as='select'
                  className='form-select'
                  options={Object.values(countries).map(
                    (country) => country.value
                  )}
                  onChange={({
                    target: { value },
                  }: React.ChangeEvent<HTMLSelectElement>) => {
                    handleOnCountryChange(value, setFieldValue);
                  }}
                  value={values.countryCode}
                >
                  {Object.values(countries).map((country) => (
                    <option key={country.value} value={country.value}>
                      {country.name}
                    </option>
                  ))}
                </Field>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-sm-6'>
              <div className='form-group'>
                <label htmlFor='streetAddress'>Street Address</label>
                <Field
                  className='form-control'
                  type='text'
                  id='streetAddress'
                  name='streetAddress'
                />
                <FieldError
                  error={errors["streetAddress"]}
                  touched={touched["streetAddress"]}
                />
              </div>
            </div>

            <div className='col-sm-6'>
              <div className='form-group'>
                <label htmlFor='street2Address'>Street Address 2</label>
                <Field
                  className='form-control'
                  type='text'
                  id='street2Address'
                  name='street2Address'
                />
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-sm-6'>
              <div className='form-group'>
                <label htmlFor='city'>City</label>
                <Field
                  className='form-control'
                  type='text'
                  id='city'
                  name='city'
                />
                <FieldError error={errors["city"]} touched={touched["city"]} />
              </div>
            </div>

            {states[values.countryCode]?.length > 0 ? (
              <div className='col-sm-3'>
                <div className='form-group'>
                  <label htmlFor='stateCode'>
                    {getLabel(values.countryCode, "state")}
                  </label>
                  <Field
                    id='stateCode'
                    name='stateCode'
                    as='select'
                    className='form-select'
                    options={[
                      { value: "", name: "Please Select" },
                      ...states[values.countryCode],
                    ]}
                  >
                    {[
                      { value: "", name: "Please Select" },
                      ...states[values.countryCode],
                    ].map((state) => (
                      <option value={state.value} key={state.value}>
                        {state.name}
                      </option>
                    ))}
                  </Field>
                  <FieldError
                    error={errors["stateCode"]}
                    touched={touched["stateCode"]}
                  />
                </div>
              </div>
            ) : (
              <div className='col-sm-3'>
                <div className='form-group'>
                  <label htmlFor='stateCode' className='fs-6'>
                    {getLabel(values.countryCode, "state")}
                    <span className='text-danger h6'>*</span>
                  </label>
                  <Field
                    name='stateCode'
                    className='form-control'
                    id='stateCode'
                  />
                  <FieldError
                    error={errors["stateCode"]}
                    touched={touched["stateCode"]}
                  />
                </div>
              </div>
            )}
            <div className='col-sm-3'>
              <div className='form-group'>
                <label htmlFor='postalCode'>
                  {getLabel(values.countryCode, "zipCode")}
                </label>
                <Field
                  className='form-control'
                  type='text'
                  id='postalCode'
                  name='postalCode'
                />
                <FieldError
                  error={errors["postalCode"]}
                  touched={touched["postalCode"]}
                />
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-sm-6'>
              <div className='form-group'>
                <label htmlFor='emailAddress'>
                  {t("frontend.account.emailAddress")}
                </label>
                <Field
                  className='form-control'
                  type='text'
                  id='emailAddress'
                  name='emailAddress'
                />
                <FieldError
                  error={errors["emailAddress"]}
                  touched={touched["emailAddress"]}
                />
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='form-group'>
                <label htmlFor='phoneNumber'>
                  {t("frontend.account.phoneNumber")}
                </label>
                <Field
                  className='form-control'
                  type='text'
                  id='phoneNumber'
                  name='phoneNumber'
                />
                <FieldError
                  error={errors["phoneNumber"]}
                  touched={touched["phoneNumber"]}
                />
              </div>
            </div>
          </div>

          <div className='d-flex align-items-start gap-2'>
            <Field
              type='checkbox'
              name='saveAddress'
              id='saveAddress'
              checked={values.saveAddress}
              onChange={({
                target: { checked },
              }: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue("saveAddress", checked)
              }
            />
            <label htmlFor='saveAddress'>Save this address</label>
          </div>

          {values.saveAddress ? (
            <div className='form-group col-sm-6'>
              <label htmlFor='accountAddressName'>
                {t("frontend.account.nickname")}
              </label>
              <Field
                className='form-control'
                type='text'
                id='accountAddressName'
                name='accountAddressName'
              />
              <FieldError
                error={errors["accountAddressName"]}
                touched={touched["accountAddressName"]}
              />
            </div>
          ) : null}

          <div className='d-flex gap-2 justify-content-end align-items-start mt-2'>
            <button type='submit' className='btn btn-primary'>
              Save
            </button>
            <button
              type='submit'
              className='btn btn-primary'
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
