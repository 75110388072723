import { Link } from "../common/types/link";
import { getTrackOrderLink } from "./utils/getTrackOrderLink";

type Data = {
  orderPayments: any[];
  orderInfo: any[];
  orderItems: any[];
  orderFulfillments: any[];
};

type Item = {
  name: string;
  quantity: number;
  skuCode: string;
  totalPrice: number;
  unitPrice: number;
};

type Costs = {
  shipping: number;
  subtotal: number;
  total: number;
};

const addressFields = [
  "city",
  "emailAddress",
  "name",
  "phoneNumber",
  "postalCode",
  "shippingMethod",
  "stateCode",
  "streetAddress",
  "streetCode",
] as const;

type AddressFields = (typeof addressFields)[number];
type Address = Record<AddressFields, string | null>;

const addressPlaceholder = {
  city: null,
  emailAddress: null,
  name: null,
  phoneNumber: null,
  postalCode: null,
  shippingMethod: null,
  stateCode: null,
  streetAddress: null,
  streetCode: null,
} satisfies Address;

export const normalizeAddress = (
  addressSource: Record<string, string | null>
) =>
  Object.getOwnPropertyNames(addressSource)
    .filter((key) => {
      const escapedKey = key.split("_").at(-1);
      if (!escapedKey) return false;

      return addressFields.includes(escapedKey as AddressFields);
    })
    .reduce((acc, key) => {
      const escapedKey = key.split("_").at(-1);
      if (!escapedKey) return acc;

      acc[escapedKey as AddressFields] = addressSource[key];

      return acc;
    }, {} as Partial<Address>);

export const parseAddress = (
  addressSource: Record<string, string | null>,
  addressFallbackSource?: Record<string, string | null>
) => {
  const parsedSource = normalizeAddress(addressSource);
  const parsedFallbackSource = addressFallbackSource
    ? normalizeAddress(addressFallbackSource)
    : {};

  return Object.assign(
    {},
    addressPlaceholder,
    parsedFallbackSource,
    parsedSource
  );
};

export class OrderDetailsModel {
  billingAddress: Address;
  costs: Costs;
  date: string;
  id: string;
  items: Item[];
  poNumber: string;
  shippingAddress: Address;
  status: string;
  notes: string;
  deliveryInstructions: string;
  trackYourOrderLink: Partial<Link> | null = null;
  orderNumber: string;

  constructor(data: Data) {
    const payment = data.orderPayments[0];
    const info = data.orderInfo[0];

    this.costs = {
      shipping: payment.order_calculatedFulfillmentTotal,
      subtotal: payment.order_calculatedSubTotal,
      total: payment.order_calculatedTotal,
    };
    this.billingAddress = parseAddress(info, payment);
    this.date = info.orderOpenDateTime;
    this.id = data.orderFulfillments[0].order_orderID;
    this.items = data.orderItems.map((item) => ({
      name: item.sku_product_productName,
      quantity: item.quantity,
      skuCode: item.sku_skuCode,
      totalPrice: item.calculatedExtendedPriceAfterDiscount,
      unitPrice: item.price,
    }));
    this.poNumber = payment.purchaseOrderNumber;
    this.shippingAddress = parseAddress(data.orderFulfillments[0]);
    this.status = data.orderPayments[0].paymentMethod_paymentMethodName;
    this.notes = data.orderInfo[0].orderNotes || "No notes added";
    this.deliveryInstructions = data.orderInfo[0].OrderDeliveryInstructions;
    this.trackYourOrderLink = getTrackOrderLink({
      trackingNumber: data.orderInfo[0].pro,
      provider: data.orderInfo[0].shipVia,
      carrier: data.orderInfo[0].carrier,
    });
    this.orderNumber = data.orderInfo[0].orderNumber;
  }
}
