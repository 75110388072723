import { UserAccountAddress } from "../../../types/addresses";

type Props = {
  addresses: UserAccountAddress[];
  primaryAddressID: string;
};

export const getAddresses = ({ addresses, primaryAddressID }: Props) => {
  const isPrimaryAddress = (id: string) => id === primaryAddressID;

  const primaryAddress = addresses.find((account) =>
    isPrimaryAddress(account?.accountAddressID)
  );

  const shippingAddresses = addresses
    .filter(({ accountAddressID }) => !isPrimaryAddress(accountAddressID))
    .map(({ address, accountAddressName, accountAddressID }) => ({
      ...address,
      accountAddressName,
      accountAddressID,
    }));

  return {
    billingAddress: primaryAddress
      ? {
          ...primaryAddress.address,
          accountAddressName: primaryAddress.accountAddressName,
        }
      : null,
    shippingAddresses,
  };
};
