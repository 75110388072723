import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getCountriesAndAddressOptions,
  getDefaultCountry,
  getStateCodeOptionsByCountryCode,
  useElementContext,
} from "@ultracommerce/react-storefront/global";
import { RootStateType } from "@/types/state";
import { useAccountAddresses } from "../../common/hooks/useAccountAddresses";
import { BillingAddressForm } from "./BillingAddressForm/BillingAddressForm";
import { getValidCountries } from "../../common/utils/getValidCountries";

type Props = {
  onSave: (values: any) => void;
  onCancel: () => void;
};

const BillingAddress = ({ onSave, onCancel }: Props) => {
  const dispatch = useDispatch();
  const defaultCountryCode = useSelector(getDefaultCountry);

  const stateCodeOptions = useSelector(
    (state: RootStateType) => state.content.stateCodeOptions
  );

  const countries = useSelector(
    (state: RootStateType) => state.content.countries
  );

  useEffect(() => {
    if (!Object.keys(countries)?.length)
      dispatch(getCountriesAndAddressOptions() as any);
    dispatch(getStateCodeOptionsByCountryCode(defaultCountryCode) as any);
  }, [countries, defaultCountryCode, dispatch]);

  const { states = [] } = countries[defaultCountryCode] || {};

  if (!Object.keys(countries)?.length) return null;

  return (
    <BillingAddressForm
      countries={getValidCountries(countries)}
      states={stateCodeOptions}
      onSave={onSave}
      onCancel={onCancel}
      defaultContry={defaultCountryCode?.toUpperCase()}
      defaultState={states[0].value}
    />
  );
};

type FulfilmentAddressSelectorProps = {
  onSelect: (value: ChangeEvent<HTMLSelectElement>) => any;
  onSave: (value: ChangeEvent<HTMLSelectElement>) => any;
  fulfillment: any;
  addressTitle: string;
};

const FulfilmentAddressSelector = ({
  onSelect,
  onSave,
  fulfillment,
  addressTitle = "frontend.account.addresses",
}: FulfilmentAddressSelectorProps) => {
  const {
    CommonModule: { SwRadioSelect },
  } = useElementContext();
  const { shippingAddresses } = useAccountAddresses();
  const { accountAddress, shippingAddress } = fulfillment;
  const [changeAddress, setChangeAddress] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const { t } = useTranslation();
  const hasShippingAddress = shippingAddress?.addressID?.length > 0;
  const showAddressCard =
    !changeAddress && shippingAddress?.addressID?.length > 0;
  const showAddressPicker =
    (changeAddress || shippingAddress?.addressID?.length === 0) && !showAddress;

  return (
    <div className='fulfilmentAddressSelector'>
      <h5 className='h5 pt-1 pb-2 mb-3 border-bottom'>
        Select a Shipping Address
      </h5>

      {showAddressCard && (
        <div className='row '>
          <div
            className='bg-lightgray rounded mb-2 col-md-4 p-3'
            key={shippingAddress?.addressID}
          >
            <div>
              <b>{shippingAddress.name}</b>
              <br />
              {shippingAddress.streetAddress} {shippingAddress?.street2Address}{" "}
              <br />
              {`${shippingAddress.city}, ${shippingAddress.stateCode} ${shippingAddress.postalCode}`}{" "}
              <br />
            </div>
            <hr />
            <button
              className='btn btn-link p-0'
              type='button'
              disabled={false}
              onClick={(event) => {
                event.preventDefault();
                setChangeAddress(true);
              }}
            >
              <i className='bi bi-times-circle'></i>
              <span className='small'>
                {" "}
                {t(`frontend.core.changeSelection`)}
              </span>
            </button>
          </div>
        </div>
      )}
      {showAddressPicker && (
        <div className='row mb-2'>
          <div className='col-sm-12'>
            {!showAddress && (
              <SwRadioSelect
                options={shippingAddresses.map(
                  ({
                    accountAddressName,
                    accountAddressID,
                    streetAddress,
                    street2Address,
                    city,
                    stateCode,
                    postalCode,
                  }) => {
                    const addressString = ` ${streetAddress} ${street2Address}, ${city}, ${stateCode} ${postalCode}`;
                    const name = accountAddressName
                      ? `${accountAddressName} - ${addressString} `
                      : addressString;
                    return { name, value: accountAddressID };
                  }
                )}
                onChange={(value: ChangeEvent<HTMLSelectElement>) => {
                  !hasShippingAddress && setShowAddress(false);
                  onSelect(value).then(() => {
                    setChangeAddress(false);
                  });
                }}
                //@ts-ignore
                customLabel={t("frontend.checkout.receive_option")}
                selectedValue={accountAddress?.accountAddressID}
                displayNew={true}
              />
            )}
            {!showAddress && (
              <button
                className='btn btn-primary mt-2'
                onClick={() => setShowAddress(true)}
              >
                {t("frontend.account.address.add")}
              </button>
            )}
          </div>
        </div>
      )}
      {showAddress && (
        <>
          {/*@ts-ignore */}
          <BillingAddress
            onSave={(values) => {
              onSave(values).then(() => {
                setChangeAddress(false);
                setShowAddress(false);
              });
            }}
            onCancel={() => setShowAddress(false)}
          />
        </>
      )}
    </div>
  );
};

export { FulfilmentAddressSelector, BillingAddress };
