import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import {
  ComponentContextProvider,
  ElementContextProvider,
  ServiceContextProvider,
  AppSwitcher,
  initApp,
  CommonModule,
  PageModule,
  CartModule,
} from "@ultracommerce/react-storefront/global";

import "./i18n";
import store from "./createStore";
import "./assets/theme";
import preload from "./preload";
import RepDashboardComponent from "./components/RepDashboard/RepDashboard.component";
import MyAccount from "./pages/MyAccount";
import { AccountInformation } from "./components/AccountInformation/AccountInformation";
import CreateOrEditAccountAddressComponent from "./components/AccountInformation/components/CreateOrEditAccountAddress/CreateOrEditAccountAddress.component";
import { CreateOrEditAccountPaymentMethod } from "./components/AccountInformation/components/CreateOrEditAccountPaymentMethod/CreateOrEditAccountPaymentMethod";
import Cart from "./pages/Cart/Cart.component";
import CartOrderSummary from "./components/Cart/CartOrderSummary/CartOrderSummary";
import StepsHeader from "./components/Checkout/StepsHeader/StepsHeader";
import OrderConfirmation from "./pages/OrderConfirmation/OrderConfirmation";
import HeaderContent from "./components/Header/HeaderContent";
import DynamicPage from "./pages/DynamicPage/DynamicPage";
import { PaymentSlide } from "./components/Checkout/Payment/Payment";
import { PaymentAddressSelector } from "./components/Checkout/Payment/components/PaymentAddressSelector/PaymentAddressSelector";
import { CCDetails } from "./components/Checkout/Payment/components/CCDetails/CCDetails";
import OrderHistory from "./components/OrderHistory/OrderHistory";
import { CartLineItem } from "./components/Cart/CartLineItem/CartLineItem.component";
import { BillingAddressDetails } from "./components/Checkout/Payment/components/BillingAddressDetails/BillingAddressDetails";
import { TermPaymentDetails } from "./components/Checkout/Payment/components/TermPaymentDetails/TermPaymentDetails";
import { MenuItem } from "./components/MenuItem/MenuItem";
import { FulfilmentAddressSelector } from "./components/Checkout/FulfilmentAddressSelector/FulfilmentAddressSelector";
import { ShippingFulfillment } from "./components/Checkout/ShippingFullfilment/ShippingFullfilment";

initApp(preload);

CommonModule.AccountOverview = RepDashboardComponent;
// @ts-ignore this components does not follow ultracommerce MVC architecture
CommonModule.AccountProfile = AccountInformation;
CommonModule.CreateOrEditAccountAddress = CreateOrEditAccountAddressComponent;
CommonModule.CreateOrEditAccountPaymentMethod =
  CreateOrEditAccountPaymentMethod;
PageModule.Cart = Cart;
PageModule.MyAccount = MyAccount;
PageModule.DynamicPage = DynamicPage;
CommonModule.CartOrderSummary = CartOrderSummary;
CommonModule.FulfillmentPicker = () => <div></div>;
// @ts-ignore this components does not follow ultracommerce MVC architecture
CommonModule.FulfilmentAddressSelector = FulfilmentAddressSelector;

// @ts-ignore this components does not follow ultracommerce MVC architecture
CommonModule.StepsHeader = StepsHeader;
// @ts-ignore this components does not follow ultracommerce MVC architecture
PageModule.OrderConfirmation = OrderConfirmation;
CommonModule.HeaderContent = HeaderContent;
// @ts-ignore this components does not follow ultracommerce MVC architecture
CommonModule.PaymentSlide = PaymentSlide;
CommonModule.PaymentAddressSelector = PaymentAddressSelector;
// @ts-ignore this components does not follow ultracommerce MVC architecture
CommonModule.CCDetails = CCDetails;

CommonModule.AccountOrderHistory = OrderHistory;
CartModule.CoreComponents.CartLineItem = CartLineItem;
// @ts-ignore this components does not follow ultracommerce MVC architecture
CommonModule.AnalyticsManager = () => null;
// @ts-ignore this components does not follow ultracommerce MVC architecture
CommonModule.BillingAddressDetails = BillingAddressDetails;
// @ts-ignore this components does not follow ultracommerce MVC architecture
CommonModule.TermPaymentDetails = TermPaymentDetails;
CommonModule.ShippingFulfillment = ShippingFulfillment;
CommonModule.MenuItem = MenuItem;
// @ts-ignore this components does not follow ultracommerce MVC architecture
CartModule.CoreComponents.CartPromoBox = () => null;

createRoot(document.getElementById("app")!).render(
  <Provider store={store}>
    <Router>
      <ServiceContextProvider>
        <ElementContextProvider>
          <ComponentContextProvider>
            <AppSwitcher />
          </ComponentContextProvider>
        </ElementContextProvider>
      </ServiceContextProvider>
    </Router>
  </Provider>
);
