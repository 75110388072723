import { useComponentData } from "./CreateOrEditAccountAddress.componentService";
import { CreateOrEditAccountAddressComponentView } from "./CreateOrEditAccountAddress.componentView";

type Props = {
  path: string;
};

const CreateOrEditAccountAddressComponent = ({ path }: Props) => {
  const { componentData, componentServices } = useComponentData({ path });

  return (
    <CreateOrEditAccountAddressComponentView
      componentData={componentData}
      onAddNewAccountAddress={componentServices.onAddNewAccountAddress}
      onUpdateAccountAddress={componentServices.onUpdateAccountAddress}
      onDeleteAccountAddress={componentServices.onDeleteAccountAddress}
      getCountryStates={componentServices.getCountryStates}
    />
  );
};

export default CreateOrEditAccountAddressComponent;
