import { RootStateType } from "@/types/state";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

type Props = {
  fulfillment: any;
  onShipmentSelect: (values: any, id: string) => any;
  onShipmentSave: (values: any, id: string) => any;
  onSelectShippingMethod: (values: any, id: string) => any;
};

const ShippingFulfillment = ({
  fulfillment,
  onShipmentSelect,
  onShipmentSave,
  onSelectShippingMethod,
}: Props) => {
  const { orderFulfillmentID } = fulfillment;
  const custDetails = useSelector((state: RootStateType) => state.userReducer);
  const { t } = useTranslation();
  const {
    CommonModule: { FulfilmentAddressSelector, ShippingMethodRates },
  } = useElementContext();

  return (
    <div className='shippingFulfillment'>
      <div>
        <div className='mb-4'>
          <h5 className='h5 pt-1 pb-2 mb-3 border-bottom confirm-details-title'>
            Personal Information
          </h5>
          <div className='row'>
            <div className='me-2 d-flex col-5 flex-wrap'>
              <b>{t("frontend.contact.firstName")}: &nbsp;</b>
              <p>{custDetails?.firstName}</p>
            </div>
            <div className='d-flex col-5 flex-wrap'>
              <b>{t("frontend.contact.lastName")}: &nbsp;</b>
              <p>{custDetails?.lastName}</p>
            </div>
          </div>
          <div className='row'>
            <div className='me-2 d-flex col-5 flex-wrap'>
              <b>{t("frontend.account.company")}: &nbsp;</b>
              <p>{custDetails?.company}</p>
            </div>
            <div className='d-flex col-5 flex-wrap'>
              <b>{t("frontend.contact.emailAddress")}: &nbsp;</b>
              <p>{custDetails?.primaryEmailAddress.emailAddress}</p>
            </div>
          </div>
          <div className='row'>
            <div className='d-flex flex-wrap'>
              <b>{t("frontend.contact.phoneNumber")}: &nbsp;</b>
              <p>{custDetails?.primaryPhoneNumber.phoneNumber}</p>
            </div>
          </div>
        </div>

        <FulfilmentAddressSelector
          fulfillment={fulfillment}
          onSelect={(value: any) => onShipmentSelect(value, orderFulfillmentID)}
          onSave={(values: any) => onShipmentSave(values, orderFulfillmentID)}
        />

        <ShippingMethodRates
          fulfillment={fulfillment}
          onSelection={(values: any) =>
            onSelectShippingMethod(values, orderFulfillmentID)
          }
        />
      </div>
    </div>
  );
};
export { ShippingFulfillment };
