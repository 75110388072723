import { isValidCountry } from "./getValidCountries";

type Fields = "state" | "zipCode";

type Countries = "US" | "CA";

export const LABELS: Record<Countries, Record<Fields, string>> = {
  CA: {
    state: "Province",
    zipCode: "Postal Code",
  },
  US: {
    state: "State",
    zipCode: "Zip Code",
  },
};

export const getLabel = (country: string, name: Fields) => {
  if (!isValidCountry(country)) return "";

  return LABELS[country][name];
};
