import * as Yup from "yup";
import { ALLOWED_COUNTRIES, AllowedCountries } from "../utils";

const PHONE_REG_EXP = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

const US_POSTAL_CODE_REG_EXP = /\d{5}([ \-]\d{4})?/;
const CA_POSTAL_CODE_REG_EXP =
  /[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d/;

const POSTAL_CODE_REG_EXP_CONFIG = {
  US: US_POSTAL_CODE_REG_EXP,
  CA: CA_POSTAL_CODE_REG_EXP,
} as const satisfies Record<AllowedCountries, RegExp>;

const ERROR_MESSAGES = {
  REQUIRED: "Required",
  AT_MOST: (numberOfChars: number) => `At Most ${numberOfChars} Characters`,
  MUST_BE_VALID: (fieldName: string) => `${fieldName} Must Be Valid`,
};

export const shippingAddressSchema = Yup.object().shape({
  accountAddressName: Yup.string()
    .required(ERROR_MESSAGES.REQUIRED)
    .max(100, ERROR_MESSAGES.AT_MOST(100)),
  streetAddress: Yup.string()
    .required(ERROR_MESSAGES.REQUIRED)
    .max(100, ERROR_MESSAGES.AT_MOST(100)),
  city: Yup.string()
    .required(ERROR_MESSAGES.REQUIRED)
    .max(100, ERROR_MESSAGES.AT_MOST(100)),
  phoneNumber: Yup.string()
    .required(ERROR_MESSAGES.REQUIRED)
    .matches(PHONE_REG_EXP, {
      excludeEmptyString: true,
      message: ERROR_MESSAGES.MUST_BE_VALID("Phone Number"),
    }),
  emailAddress: Yup.string()
    .required(ERROR_MESSAGES.REQUIRED)
    .email(ERROR_MESSAGES.MUST_BE_VALID("Email")),
  stateCode: Yup.string().required(ERROR_MESSAGES.REQUIRED),
  countryCode: Yup.string()
    .required(ERROR_MESSAGES.REQUIRED)
    .oneOf([...ALLOWED_COUNTRIES], ERROR_MESSAGES.MUST_BE_VALID("Country")),
  postalCode: Yup.string()
    .required(ERROR_MESSAGES.REQUIRED)
    .when("countryCode", (countryCode: AllowedCountries, schema) =>
      schema.matches(
        POSTAL_CODE_REG_EXP_CONFIG[countryCode],
        ERROR_MESSAGES.MUST_BE_VALID("Postal Code")
      )
    ),
  firstName: Yup.string()
    .required(ERROR_MESSAGES.REQUIRED)
    .max(100, ERROR_MESSAGES.AT_MOST(100)),
  lastName: Yup.string()
    .required(ERROR_MESSAGES.REQUIRED)
    .max(100, ERROR_MESSAGES.AT_MOST(100)),
});
