import {
  Address,
  CountryCodeOption,
  StateCodeOptions,
} from "@/types/addresses";

type Props = {
  isEdit: boolean;
  heading: string;
  shippingAddress: Address | null;
  countryCodeDropdown: CountryCodeOption[];
  stateCodeDropdown: StateCodeOptions | null;
  defaultAddress: Address;
  isFormDataReady: boolean;
};

export class CreateOrEditAccountAddressModel {
  isEdit: boolean;
  heading: string;
  shippingAddress: Address | null;
  countryCodeDropdown: CountryCodeOption[];
  stateCodeDropdown: StateCodeOptions | null;
  defaultAddress: Address;
  isFormDataReady: boolean;
  constructor({
    shippingAddress,
    countryCodeDropdown,
    heading,
    isEdit,
    stateCodeDropdown,
    defaultAddress,
    isFormDataReady,
  }: Props) {
    this.shippingAddress = shippingAddress;
    this.countryCodeDropdown = countryCodeDropdown;
    this.heading = heading;
    this.isEdit = isEdit;
    this.stateCodeDropdown = stateCodeDropdown;
    this.defaultAddress = defaultAddress;
    this.isFormDataReady = isFormDataReady;
  }
}
