const paymentMethods = ["creditCard", "payByTerm30Days"] as const;

export type PaymentMethod = (typeof paymentMethods)[number];

const PAYMENT_METHODS_LABELS: Record<PaymentMethod, string> = {
  creditCard: "Credit Card",
  payByTerm30Days: "Pay by Term (30 Days)",
};

const isAllowedPaymentMethod = (method: string): method is PaymentMethod =>
  paymentMethods.includes(method as PaymentMethod);

export const getPaymentMethod = (method: string) => {
  if (!isAllowedPaymentMethod(method)) return "";

  return PAYMENT_METHODS_LABELS[method];
};
