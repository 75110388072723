import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  isVatCountry,
  useFormatCurrency,
} from "@ultracommerce/react-storefront/global";
import { Cart } from "@/types/cart";
import { RootStateType } from "@/types/state";

const CartOrderSummary = () => {
  const cart: Cart = useSelector((state: RootStateType) => state.cart);
  const [formatCurrency] = useFormatCurrency({});
  const { t } = useTranslation();
  const showVat: boolean = useSelector(isVatCountry);

  return (
    <div className='card mb-4'>
      <div className='card-header'>
        <h4 className='mb-0 order-summary-title pt-2 pb-2'>
          {t("frontend.cart.orderSummary")}
        </h4>
      </div>
      <ul className='list-group list-group-flush '>
        <li className='list-group-item d-flex justify-content-between align-items-center'>
          <h6 className='my-0'> {t("frontend.checkout.subTotal")}</h6>
          <span className='float-end'>
            <strong>
              {cart.subtotal > 0 ? formatCurrency(cart.subtotal) : "--"}
            </strong>
          </span>
        </li>
        <li className='list-group-item d-flex justify-content-between align-items-center'>
          <h6 className='my-0'>Drop Ship Fee</h6>
          <span className='float-end'>
            <strong>
              {cart.fulfillmentTotal > 0
                ? formatCurrency(cart.fulfillmentTotal)
                : "--"}
            </strong>
          </span>
        </li>

        <li className='list-group-item d-flex justify-content-between'>
          <h6 className='my-0'>{t("frontend.cart.total")}</h6>
          <strong>{cart.total > 0 ? formatCurrency(cart.total) : "--"}</strong>
        </li>
        {showVat && (
          <li className='list-group-item d-flex justify-content-between '>
            <h6 className='my-0'>{t("frontend.cart.vat")}</h6>
            <span className='float-end'>
              <strong>
                {cart.VATTotal > 0 ? formatCurrency(cart.VATTotal) : "--"}
              </strong>
            </span>
          </li>
        )}
      </ul>
    </div>
  );
};

export default CartOrderSummary;
