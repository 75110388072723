const VALID_COUNTRIES = ["CA", "US"] as const;

type ValidCountry = (typeof VALID_COUNTRIES)[number];

export const isValidCountry = (country: string): country is ValidCountry =>
  VALID_COUNTRIES.includes(country as ValidCountry);

export const getValidCountries = (
  countries: Record<string, Record<string, string>>
) => {
  const mappedCountries = {} as Record<ValidCountry, Record<string, string>>;

  for (const key in countries) {
    if (isValidCountry(key)) mappedCountries[key] = countries[key];
  }

  return mappedCountries;
};
