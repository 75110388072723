import { OrderItem } from "@/types/cart";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { CartLineItem } from "../CartLineItem/CartLineItem.component";

export interface CartItemListProps {
  title: string;
  cartItems: (OrderItem & { errorMsg?: string })[];
  orderItems: OrderItem[];
  isRemovingItem: boolean;
  onRemoveItem: (id: string) => void;
  onUpdateQty: (orderItemID: string, quantity: number) => void;
}

const CartItemList = ({
  title,
  cartItems,
  isRemovingItem,
  orderItems,
  onRemoveItem,
  onUpdateQty,
}: CartItemListProps) => {
  const { CartModule } = useElementContext();
  CartModule.CoreComponents.CartLineItem = CartLineItem;
  return (
    <div className='card mb-4 border-0'>
      <h3 className='pt-0 mb-0 card-header bg-white'>{title}</h3>
      <div className='card-body p-0'>
        {cartItems.map((orderItem, key) => {
          const childBundleItems = orderItems?.filter(
            (item) => item?.parentOrderItemID === orderItem.orderItemID
          );
          return (
            <CartModule.CoreComponents.CartLineItem
              key={`${orderItem.orderItemID}-${key}`}
              orderItem={orderItem}
              childBundleItems={childBundleItems}
              isRemovingItem={isRemovingItem}
              onRemoveItem={onRemoveItem}
              onUpdateQty={onUpdateQty}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CartItemList;
