import { Link } from "react-router-dom";
import { useAccountProfile } from "./hooks/useAccountProfile";

export const AccountProfile = () => {
  const { account } = useAccountProfile();

  if (!account) return null;

  return (
    <div className='bg-white p-4'>
      <div className='d-flex justify-content-between align-items-center'>
        <h2 className='pt-0 mb-4'>Account Information</h2>
        <Link to='edit'>Edit</Link>
      </div>

      <div className='d-flex gap-4'>
        <div className='col'>
          <h4 className='pt-0'>First Name</h4>
          <div className='border p-2'>{account.firstname}</div>
        </div>
        <div className='col'>
          <h4 className='pt-0'>Last Name</h4>
          <div className='border p-2'>{account.lastname}</div>
        </div>
      </div>

      <div>
        <h4>Email</h4>
        <div className='border p-2'>{account.email}</div>
      </div>
      <div>
        <h4>Billing Email</h4>
        <div className='border p-2'>{account.billingEmail}</div>
      </div>
      <div>
        <h4>Salesforce ID</h4>
        <div className='border p-2'>{account.salesforeId}</div>
      </div>
      <div>
        <h4>Customer ID</h4>
        <div className='border p-2'>{account.customerId}</div>
      </div>

      <div className='d-flex gap-4'>
        <div className='col'>
          <h4>Company</h4>
          <div className='border p-2'>{account.company}</div>
        </div>
        <div className='col'>
          <h4>Website</h4>
          <div className='border p-2'>
            {account.website || "No Website Provided"}
          </div>
        </div>
      </div>

      <div className='mt-4'>
        <h4 className='pt-0'>Communication Preferences</h4>
        <div className='d-flex align-items-start gap-2'>
          <input
            type='checkbox'
            disabled
            checked={account.communiactionPreferences}
          />
          <div>
            <span className='text-danger'>*</span>
            Manage communication preferences... check this box if lorem ipsum
            dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat. Duis aute irure dolor in reprehenderit in
            voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
            officia deserunt mollit anim id est laborum
          </div>
        </div>
      </div>
    </div>
  );
};
