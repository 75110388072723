import { OrderItem } from "@/types/cart";

export interface CartLineItemProps {
  childBundleItems?: OrderItem[];
  orderItem: OrderItem & { errorMsg?: string };
  onRemoveItem?: (itemId: string) => void;
  onUpdateQty?: (orderItemID: string, quantity: number) => void;
  isDisabled?: boolean;
  isRemovingItem?: boolean;
}

export class CartLineItemModel {
  childBundleItems?: OrderItem[];
  orderItem!: OrderItem & { errorMsg?: string };
  isDisabled: boolean = false;
  isRemovingItem: boolean = false;
  itemCount: number = 0;
  clientCustom: any;
  constructor({
    props,
    itemCount,
  }: {
    props: CartLineItemProps;
    itemCount: number;
  }) {
    this.childBundleItems = props.childBundleItems;
    this.orderItem = props.orderItem;
    this.isDisabled = !!props.isDisabled;
    this.isRemovingItem = !!props.isRemovingItem;

    this.itemCount = itemCount;
  }
}
