import { Link } from "@/components/common/types/link";

const TRACK_YOUR_ORDER_PROVIDERS = [
  "CEVA",
  "DEMAR",
  "FEDEX GROUND",
  "FEDEX LTL",
  "NEW ENGL",
  "NSD",
  "UPS LTL",
  "YRC",
  "R&L",
  "RL",
  "ABF",
  "AGS",
  "Central Tr",
  "CONWAY",
  "ESTES",
  "ODFL",
  "SEKO",
  "UPS GROUND",
  "WatkinShep",
] as const;

type TrackYourOrderProviders = (typeof TRACK_YOUR_ORDER_PROVIDERS)[number];

type TrackOrderItem = {
  url: string;
  addTrackingNumber?: boolean;
};

const TRACK_YOUR_ORDER_CONFIG: Record<TrackYourOrderProviders, TrackOrderItem> =
  {
    CEVA: {
      url: "https://www.cevalogistics.com/ceva-trak?sv=<NUMBER>&uid=",
      addTrackingNumber: true,
    },
    DEMAR: {
      url: "http://www.demarlogistics.com/Track-a-Shipment/TrackingRef/<NUMBER>",
      addTrackingNumber: true,
    },
    "FEDEX GROUND": {
      url: "https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=<NUMBER>",
      addTrackingNumber: true,
    },
    "FEDEX LTL": {
      url: "https://www.fedex.com/apps/fedextrack/index.html?tracknumbers=<NUMBER>",
      addTrackingNumber: true,
    },
    "NEW ENGL": {
      url: "http://nemfweb.nemf.com/shptrack.nsf/request?openagent=1&pro=<NUMBER>&submit=Track",
      addTrackingNumber: true,
    },
    NSD: {
      url: "http://www.nonstopdelivery.com/tracking/?tracking=<NUMBER>",
      addTrackingNumber: true,
    },
    "UPS LTL": {
      url: "http://ltl.upsfreight.com/shipping/tracking/TrackingDetail.aspx?TrackProNumber=<NUMBER>",
      addTrackingNumber: true,
    },
    YRC: {
      url: "https://my.yrc.com/tools/track/shipments?referenceNumber=<NUMBER>&referenceNumberType=PRO",
      addTrackingNumber: true,
    },
    "R&L": {
      url: "https://www.rlcarriers.com/freight/shipping/shipment-tracing?pro=<NUMBER>&docType=PRO&source=web",
      addTrackingNumber: true,
    },
    RL: {
      url: "https://www.rlcarriers.com/freight/shipping/shipment-tracing?pro=<NUMBER>&docType=PRO&source=web",
      addTrackingNumber: true,
    },
    ABF: {
      url: "https://arcb.com/tools/tracking.html",
    },
    AGS: {
      url: "http://www.aramexglobalsolutions.com/tracking",
    },
    "Central Tr": {
      url: "http://www.centraltransportint.com/confirm/trace.aspx",
    },
    CONWAY: {
      url: "https://www.con-way.com/webapp/manifestrpts_p_app/Tracking/Tracking.jsp",
    },
    ESTES: {
      url: "https://www.estes-express.com/WebApp/ShipmentTracking/",
    },
    ODFL: {
      url: "https://www.odfl.com/Trace/standard.faces",
    },
    SEKO: {
      url: "https://harmony.myseko.com/track",
    },
    "UPS GROUND": {
      url: "https://www.ups.com/tracking/tracking.html",
    },
    WatkinShep: {
      url: "http://wksh.com/ExternalTracker.aspx",
    },
  };

const isValidTrackOrderProvider = (
  provider: string
): provider is TrackYourOrderProviders =>
  TRACK_YOUR_ORDER_PROVIDERS.includes(provider as TrackYourOrderProviders);

type Props = {
  trackingNumber: string;
  provider: string;
  carrier: string;
};

export const getTrackOrderLink = ({
  trackingNumber,
  provider,
  carrier,
}: Props): Partial<Link> | null => {
  if (!trackingNumber) return null;

  if (!isValidTrackOrderProvider(provider))
    return {
      label: `${carrier} ${trackingNumber}`,
    };

  const validProvider = TRACK_YOUR_ORDER_CONFIG[provider];

  if (!validProvider?.addTrackingNumber)
    return {
      label: trackingNumber,
      url: validProvider.url,
    };

  return {
    label: trackingNumber,
    url: validProvider.url.replace("<NUMBER>", trackingNumber),
  };
};
