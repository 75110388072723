import {
  Cart,
  CartItemList,
  CartCheckOut,
  CartCheckoutOptions,
} from "@/types/cart";

class CartModel {
  orderID: string = "";
  isRemovingItem: boolean = false;
  isEmptyCart: boolean = false;
  showClearCart: boolean = false;

  components: {
    cartOrderItemList?: Omit<CartItemList, "onRemoveItem" | "onUpdateQty">;
    quoteOrderItemsList?: Omit<CartItemList, "onRemoveItem" | "onUpdateQty">;
    cartCheckOut?: Omit<CartCheckOut, "onApplyCode">;
    cartCheckoutOptions?: CartCheckoutOptions;
  } = {};

  constructor({
    cart,
    isRemovingItem,
    disableInteraction,
  }: {
    cart: Cart;
    isRemovingItem: boolean;
    disableInteraction: boolean;
  }) {
    const { orderItems = [], orderID } = cart;

    this.orderID = orderID;
    this.isEmptyCart = !orderItems.length;

    this.isRemovingItem = isRemovingItem;
    const cartAndQuoteItems = orderItems.filter(
      (item) => item.parentOrderItemID === ""
    );
    const cartOrderItems = cartAndQuoteItems.filter((item) => !item.quoteOnly);
    const quoteOrderItems = cartAndQuoteItems.filter((item) => item.quoteOnly);

    this.components.cartOrderItemList = cartOrderItems.length
      ? {
          title: `Shopping Cart (${cartOrderItems.length})`,
          cartItems: cartOrderItems,
          orderItems: orderItems,

          isRemovingItem: isRemovingItem,
        }
      : undefined;

    this.components.quoteOrderItemsList = quoteOrderItems.length
      ? {
          title: `Items which needs to be quoted (${quoteOrderItems.length})`,
          cartItems: quoteOrderItems,
          orderItems: orderItems,

          isRemovingItem: isRemovingItem,
        }
      : undefined;

    if (quoteOrderItems.length) {
      this.components.cartCheckoutOptions = {
        cartOrderItemsLength: cartOrderItems.length,
        quoteOrderItemsLength: quoteOrderItems.length,
      };
    } else {
      this.components.cartCheckOut = !this.isEmptyCart
        ? { disableCheckoutBtn: disableInteraction }
        : undefined;
    }

    this.showClearCart = !this.isEmptyCart;
  }
}

export default CartModel;
