import { useCallback } from "react";
import { Link, useLocation } from "react-router-dom";

import { useFormatCurrency } from "@ultracommerce/react-storefront/global";

import { OrderDetailsModel } from "./OrderDetails.componentModel";
import styles from "./OrderDetails.module.css";
import { formatDate } from "../common/utils/formatDate";
import { TrackYourOrderLink } from "./components/TrackYourOrderLink";

type Props = {
  componentData: OrderDetailsModel;
};

export const OrderDetailsComponentView = ({
  componentData: {
    billingAddress,
    costs,
    date,
    items,
    poNumber,
    shippingAddress,
    status,
    id,
    notes,
    deliveryInstructions,
    trackYourOrderLink,
    orderNumber,
  },
}: Props) => {
  const [formatCurrency] = useFormatCurrency({});
  const { pathname } = useLocation();

  const adjustScroll = useCallback((node: HTMLElement) => {
    node?.scrollIntoView();
  }, []);

  return (
    <article
      className={`p-3 py-4 container ${styles["order-details"]}`}
      ref={adjustScroll}
    >
      <h4 className='mb-4 pt-0 fs-5 font-weight-bold'>Order details</h4>

      <div className='d-flex justify-content-between gap-4'>
        <div className=''>
          <span className='d-block mb-2'>PO Number:</span>
          <strong>{poNumber || "No PO"}</strong>
        </div>

        <div>
          <span className='d-block mb-2'>Date:</span>
          <strong>{formatDate(date)}</strong>
        </div>

        <div>
          <span className='d-block mb-2'>Status:</span>
          <strong>{status}</strong>
        </div>

        <div className='d-flex flex-column gap-3'>
          {trackYourOrderLink ? (
            <div>
              <span className='d-block mb-2'>Track Your Order</span>
              <TrackYourOrderLink link={trackYourOrderLink} />
            </div>
          ) : null}

          <div>
            <span className='d-block mb-2'>Return:</span>
            <Link
              to={`/my-account/request-rma?dreamline___rma___purchase_order_number=${orderNumber}-${poNumber}`}
              state={{ from: pathname }}
            >
              Request Return Merchandise Authorization
            </Link>
          </div>
        </div>
      </div>

      <div className='d-flex gap-3 mt-4'>
        <div className='border p-3 col'>
          <h5 className='card-title'>Billing</h5>
          <ul className='list-unstyled card-text mt-3 lh-base'>
            <li>{billingAddress.name}</li>
            <li>{billingAddress.streetAddress}</li>
            <li>
              {billingAddress.city}, {billingAddress.stateCode}{" "}
              {billingAddress.postalCode}
            </li>
            <li className='mt-3'>{billingAddress.phoneNumber}</li>
            <li>{billingAddress.emailAddress}</li>
          </ul>
        </div>
        <div className='border p-3 col'>
          <h5 className='card-title'>Shipping</h5>
          <ul className='list-unstyled card-text mt-3 lh-base'>
            <li>{shippingAddress.name}</li>
            <li>{shippingAddress.streetAddress}</li>
            <li>
              {shippingAddress.city}, {shippingAddress.stateCode}{" "}
              {shippingAddress.postalCode}
            </li>
            <li className='mt-3'>{shippingAddress.phoneNumber}</li>
            <li>{shippingAddress.emailAddress}</li>
          </ul>
        </div>
      </div>

      <table className='table mt-5'>
        <thead>
          <tr className='bg-dark'>
            <th>Reference</th>
            <th>Product</th>
            <th className='text-end'>Quantity</th>
            <th className='text-end'>Unit price</th>
            <th className='text-end'>Total Price</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={`${item.skuCode}-${index}`} className='bg-white'>
              <td className='border-0'>{item.skuCode}</td>
              <td className='border-0'>{item.name}</td>
              <td className='text-end border-0'>{item.quantity}</td>
              <td className='text-end border-0'>
                {formatCurrency(item.unitPrice)}
              </td>
              <td className='text-end border-0'>
                {formatCurrency(item.totalPrice)}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className='bg-white'>
            <td colSpan={4} className='text-end border-0'>
              Items
            </td>
            <td className='text-end border-0'>
              {formatCurrency(costs.subtotal)}
            </td>
          </tr>

          <tr className='bg-white'>
            <td colSpan={4} className='text-end border-0'>
              Drop Ship Fee
            </td>
            <td className='text-end border-0'>
              {formatCurrency(costs.shipping)}
            </td>
          </tr>

          <tr className='bg-white'>
            <td colSpan={4} className='text-end border-0'>
              Total
            </td>
            <td className='text-end border-0'>{formatCurrency(costs.total)}</td>
          </tr>
        </tfoot>
      </table>
      {notes ? (
        <div className='mb-4'>
          <h4 className='mb-3 pt-0 fs-5 font-weight-bold'>Order Notes:</h4>
          <div className='border p-2 w-50'>{notes}</div>
        </div>
      ) : null}
      {deliveryInstructions ? (
        <div>
          <h4 className='mb-3 pt-0 fs-5 font-weight-bold'>
            Delivery Instructions:
          </h4>
          <div className='border p-2 w-50'>{deliveryInstructions}</div>
        </div>
      ) : null}
    </article>
  );
};
