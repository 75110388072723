import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { Link } from "react-router-dom";

type Props = {
  theme: any;
  siteSetting: any;
  ribbons: ReactNode[];
  megaMenus: ReactNode[];
  filteredMenus: ReactNode[];
};

const HeaderContent = ({
  ribbons,
  megaMenus = [],
  filteredMenus = [],
  theme,
  siteSetting,
}: Props) => {
  const { t } = useTranslation();
  const {
    CommonModule: {
      MobileHeader,
      MobileNav,
      SearchBar,
      AccountImpersonationBanner,
      HeaderTopLeft,
      HeaderTopRight,
      HeaderContentOne,
      HeaderContentTwo,
      HeaderContentFour,
    },
  } = useElementContext();

  const homePageUrl = siteSetting.companySocialsLinkedInUri;

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    window.location.replace(`${homePageUrl}/api/token?token=${token}`);
  };

  return (
    <>
      <AccountImpersonationBanner />
      <div
        className='header-ribbon container px-4 no-print'
        style={{
          backgroundColor: theme.headerBarColor?.trim(),
          color: theme.headerBarTextColour?.trim(),
        }}
      >
        <div className='row justify-content-evenly'>
          <HeaderTopLeft theme={theme} siteSetting={siteSetting} />
          <HeaderTopRight
            theme={theme}
            siteSetting={siteSetting}
            ribbons={ribbons}
          />
        </div>
      </div>
      <nav className='py-3 no-print main-nav'>
        <div className='container'>
          {theme.headerStyleOption === "four" ? (
            <HeaderContentFour
              theme={theme}
              siteSetting={siteSetting}
              filteredMenus={filteredMenus}
            />
          ) : (
            <div className='position-relative d-flex justify-content-between align-items-center'>
              <div
                className={`row col-12 text-center text-md-start mb-3 mb-lg-0 order-xl-1 justify-content-between ${
                  theme.headerStyleOption === "two" ||
                  theme.headerStyleOption === "four"
                    ? "col-xl-9 col-lg-9"
                    : "col-xl-4 col-lg-4"
                }`}
              >
                <div className='col-5 col-lg-6 d-flex filtered-block-div'>
                  <Link
                    onClick={(e) => handleClick(e)}
                    className='border-0 bg-transparent'
                    to={homePageUrl}
                  >
                    <span className='navbar-brand d-block'>
                      {theme && theme.logo ? (
                        <img
                          src={`${theme.logo?.trim()}`}
                          className='img-fluid navbar-brandLogo'
                          alt={t("frontend.logo")}
                          style={{ maxHeight: "60px", minWidth: "150px" }}
                        />
                      ) : null}
                    </span>
                  </Link>
                  {theme.headerStyleOption !== "two" && (
                    <div className='d-xl-flex d-none col-xl-12 flex-wrap'>
                      {theme.headerTagLine}
                    </div>
                  )}
                  {theme.headerStyleOption === "two" && (
                    <div className='d-flex flex-column justify-content-center'>
                      <div className='d-xl-flex d-none'>
                        {theme.headerTagLine}
                      </div>
                      <div className='d-none d-lg-flex'>
                        <SearchBar
                          searchBoxTypeaheadFlag
                          redirectToSearchPage
                          searchBoxPlaceholder={theme.searchPlaceholder}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <MobileHeader siteSetting={siteSetting} />
              </div>
              {theme.headerStyleOption === "one" && (
                <HeaderContentOne
                  theme={theme}
                  siteSetting={siteSetting}
                  filteredMenus={filteredMenus}
                />
              )}
              {theme.headerStyleOption !== "one" && (
                <HeaderContentTwo theme={theme} siteSetting={siteSetting} />
              )}
            </div>
          )}
          <div className='d-block d-lg-none col-12'>
            <div className=''>
              <SearchBar
                searchBoxTypeaheadFlag
                redirectToSearchPage
                searchBoxPlaceholder={theme?.searchPlaceholder}
              />
            </div>
          </div>
        </div>
      </nav>
      <MobileNav filteredMenus={filteredMenus} megaMenus={megaMenus} />
    </>
  );
};

export default HeaderContent;
