import { useState } from "react";

export const useToggle = () => {
  const [isToggledOn, setIsToggledOn] = useState(false);

  const toggleOff = () => setIsToggledOn(false);
  const toggleOn = () => setIsToggledOn(true);

  return {
    isToggledOn,
    toggleOff,
    toggleOn,
  };
};
