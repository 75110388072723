import { Link } from "react-router-dom";

type Props = {
  label: string;
  href: string;
  className?: string;
  target: "_blank" | "_self" | "_parent" | "_top";
};

export const DownloadLink = ({ label, href, className, target }: Props) => {
  if (!href) return null;
  return (
    <div>
      <div className='btn btn-secondary mt-4 d-inline-flex align-items-center'>
        <Link
          to={href}
          className={`d-flex align-items-center gap-2 text-decoration-none text-center ${
            className || ""
          }`}
          style={{
            color: "inherit",
          }}
          target={target}
        >
          <i className='bi bi-download'></i>
          <span>{label}</span>
        </Link>
      </div>
    </div>
  );
};
