import { CreditCardPayment } from "@/types/cart";
import { getAllAccountPaymentMethods } from "@ultracommerce/react-storefront/global";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

type Props = {
  creditCardPayment: CreditCardPayment;
  hideHeading: boolean;
};

const CCDetails = ({ creditCardPayment, hideHeading = false }: Props) => {
  const {
    paymentMethod,
    creditCardType,
    nameOnCreditCard,
    creditCardLastFour,
    accountPaymentMethod,
    purchaseOrderNumber,
  } = creditCardPayment;
  const paymentMethods = useSelector(getAllAccountPaymentMethods) as any[];
  const { t } = useTranslation();

  return (
    <>
      {!hideHeading && (
        <h6 className='h6'>{t("frontend.checkout.payment_method")}</h6>
      )}
      <p className='mb-1'>
        {accountPaymentMethod?.accountPaymentMethodID?.length > 0 &&
          paymentMethods.map((method) => {
            return method.accountPaymentMethodID ===
              accountPaymentMethod?.accountPaymentMethodID ? (
              <em key={accountPaymentMethod?.accountPaymentMethodID}>
                {method?.accountPaymentMethodName}
              </em>
            ) : null;
          })}
        <em>{paymentMethod?.paymentMethodName}</em>
        <br />
        <span className='fw-bold'>PO: </span>
        {purchaseOrderNumber}
        <br />
        {nameOnCreditCard} <br />
        {`${creditCardType} ending in ${creditCardLastFour}`}
      </p>
    </>
  );
};

export { CCDetails };
