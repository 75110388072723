import { useTranslation } from "react-i18next";

type Props = {
  termPayment: {
    purchaseOrderNumber: string;
    paymentMethod: {
      paymentMethodName: string;
    };
  };
  hideHeading: boolean;
};

const TermPaymentDetails = ({ termPayment, hideHeading }: Props) => {
  const { purchaseOrderNumber, paymentMethod } = termPayment;
  const { t } = useTranslation();

  return (
    <>
      {!hideHeading && (
        <h6 className='h6 fw-bold term-payment-title'>
          {t("frontend.checkout.payment_method")}
        </h6>
      )}
      <p className='mb-1'>
        <em>{paymentMethod.paymentMethodName}</em>
        <br />
        <span className='fw-bold'>PO: </span>
        {purchaseOrderNumber}
      </p>
    </>
  );
};
export { TermPaymentDetails };
