//@ts-ignore hubspot form import is not typed
import HubspotForm from "react-hubspot-form";
import { Link, useLocation } from "react-router-dom";
import { FormType, FORMS_CONFIG } from "./configuration";

type Props = {
  formType: FormType;
  backBtnTitle?: string;
};

const FALLBACK_URL = "/";

export const Hubspot = ({ formType, backBtnTitle }: Props) => {
  const location = useLocation();
  return (
    <div className='container'>
      {backBtnTitle ? (
        <Link
          to={location.state?.from ?? FALLBACK_URL}
          className='d-block my-4'
        >
          <i className='bi bi-chevron-left'></i>
          {backBtnTitle}
        </Link>
      ) : null}
      <HubspotForm {...FORMS_CONFIG[formType]} />
    </div>
  );
};
