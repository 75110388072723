import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { useState } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import {
  SlatwalApiService,
  getDefaultCountry,
  getErrorMessage,
  receiveUser,
  useCheckoutUtilities,
  useElementContext,
} from "@ultracommerce/react-storefront/global";
import { RootStateType } from "@/types/state";
import { UserAccountAddress } from "@/types/addresses";

const initialBillingAddress = {
  countryCode: null,
  name: "",
  company: "",
  phoneNumber: "",
  emailAddress: "",
  streetAddress: "",
  street2Address: "",
  city: "",
  stateCode: "",
  postalCode: "",
};
const CreateOrEditAccountPaymentMethod = () => {
  const {
    CommonModule: {
      AccountLayout,
      AccountAddressForm,
      AccountContent,
      Button,
      TextInput,
      SwSelect,
      SwRadioSelect,
      ThreeDSRedirect,
    },
  } = useElementContext();
  const accountAddresses = useSelector(
    (state: RootStateType) => state.userReducer.accountAddresses
  );
  const countryCode = useSelector(getDefaultCountry);
  const [showNewAddressForm, setShowNewAddressForm] = useState(false);
  let [redirectUrl, setRedirectUrl] = useState();
  let [redirectPayload, setRedirectPayload] = useState({});
  let [redirectMethod, setRedirectMethod] = useState("");
  let [isFetching, setFetching] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { months, years } = useCheckoutUtilities();
  const [billingAddress, setBillingAddress] = useState({
    ...initialBillingAddress,
    countryCode,
  });
  const [billingAddressErrors, setBillingAddressErrors] = useState({});
  const [billingAccountAddressIDError, setBillingAccountAddressIDError] =
    useState<string | undefined | null>();
  const [billingAccountAddressID, setBillingAccountAddressID] = useState("");
  const [paymentMethod, setPaymentMethod] = useState({
    accountPaymentMethodName: "",
    paymentMethodType: "creditCard",
    creditCardNumber: "",
    nameOnCreditCard: "",
    expirationMonth: dayjs().add(1, "month").format("MM"),
    expirationYear: dayjs().add(1, "month").format("YYYY"),
    securityCode: "",
  });
  const [paymentMethodErrors, setPaymentMethodErrors] = useState({});
  const saveCardToAccount = () => {
    const payload = {
      ...paymentMethod,
      transactionInitiator: "ACCOUNT",
      billingAccountAddress: { accountAddressID: "" },
      billingAddress: {},
      returnJSONObjects: "account",
    };
    if (billingAccountAddressID.length)
      payload.billingAccountAddress.accountAddressID = billingAccountAddressID;
    if (!billingAccountAddressID.length)
      payload.billingAddress = billingAddress;
    SlatwalApiService.account
      .addPaymentMethod(payload)
      .then((response: any) => {
        if (
          response.isSuccess() &&
          Object.keys(response.success()?.errors || {}).length
        ) {
          toast.error(getErrorMessage(response.success().errors));
        } else {
          if (response.isSuccess()) {
            if (response.success()?.redirectUrl?.length) {
              setRedirectUrl(response.success().redirectUrl);
              setRedirectPayload(response.success().redirectPayload);
              setRedirectMethod(response.success().redirectMethod);
            } else {
              toast.success(t("frontend.account.card.success"));
              setTimeout(() => {
                dispatch(receiveUser(response.success().account));
                navigate("/my-account/profile");
              }, 2000);
            }
          }
        }
        setFetching(false);
      });
  };

  const requiredValidation = ({
    value,
    name,
    msg,
  }: {
    value: string;
    name: string;
    msg: string;
  }) => {
    Yup.string()
      .required(msg)
      .validate(value, { abortEarly: false })
      .then(() => {
        let newErrors = { ...paymentMethodErrors };
        setPaymentMethodErrors(newErrors);
      })
      .catch((err) => {
        setPaymentMethodErrors(
          err.inner.reduce((acc: any, { message }: { message: string }) => {
            return {
              ...acc,
              [name]: { path: name, message },
            };
          }, paymentMethodErrors)
        );
      });
  };
  const verifyOnSubmit = () => {
    // eslint-disable-next-line
    const nameOnCreditCardValidation = /^[^*|:><[}{)(~^`"&$;@%!,._?/+=0-9]+$/;
    try {
      Yup.object()
        .shape({
          creditCardNumber: Yup.string().required("Required"),
          nameOnCreditCard: Yup.string()
            .required("Required")
            .matches(nameOnCreditCardValidation, {
              excludeEmptyString: true,
              message: t("frontend.account.nameOnCreditCardValidation"),
            }),
          expirationMonth: Yup.string().required("Required"),
          expirationYear: Yup.string().required("Required"),
          securityCode: Yup.string().required("Required"),
          accountPaymentMethodName: Yup.string().required("Required"),
        })
        .validateSync(paymentMethod, { abortEarly: false });
    } catch (err) {
      setPaymentMethodErrors(
        (err as any)?.inner?.reduce(
          (acc: any, { path, message }: { path: string; message: string }) => {
            return {
              ...acc,
              [path]: { path, message },
            };
          },
          paymentMethodErrors
        )
      );
      return false;
    }
    if (!showNewAddressForm && billingAccountAddressID.length === 0) {
      setBillingAccountAddressIDError("Required" as string);
      return false;
    } else if (!showNewAddressForm && billingAccountAddressID.length > 0) {
      return true;
    } else {
      try {
        Yup.object()
          .shape({
            name: Yup.string().required("Required"),
            streetAddress: Yup.string().required("Required"),
            city: Yup.string().required("Required"),
            stateCode: Yup.string().required("Required"),
            postalCode: Yup.string().required("Required"),
          })
          .validateSync(billingAddress, { abortEarly: false });
        return true;
      } catch (err) {
        setBillingAddressErrors(
          (err as any).inner.reduce(
            (
              acc: any,
              { path, message }: { path: string; message: string }
            ) => {
              return {
                ...acc,
                [path]: { path, message },
              };
            },
            billingAddressErrors
          )
        );
        return false;
      }
    }
  };
  if (redirectUrl)
    return (
      <ThreeDSRedirect
        url={redirectUrl}
        payload={redirectPayload}
        method={redirectMethod}
      />
    );
  return (
    <AccountLayout>
      <AccountContent />
      <form className='mt-5 bg-white p-4'>
        <div className='row'>
          <div className='col-md-6'>
            <div className='form-group'>
              <label htmlFor='paymentMethodType'>
                {t("frontend.account.payment_method.heading")}
              </label>
              <SwSelect
                id='paymentMethodType'
                value={paymentMethod.paymentMethodType}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPaymentMethod({
                    ...paymentMethod,
                    paymentMethodType: e.target.value,
                  });
                }}
                options={[
                  {
                    key: "Credit Card",
                    value: "creditCard",
                  },
                ]}
              />
            </div>
          </div>
          <div className='col-md-12'>
            <h5 className='my-2'>
              {t("frontend.account.payment_method.cc_details")}
            </h5>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <TextInput
                name={paymentMethod.accountPaymentMethodName}
                label={t("frontend.account.payment_method.nickname")}
                value={paymentMethod.accountPaymentMethodName}
                isError={
                  !!(paymentMethodErrors as any)?.accountPaymentMethodName
                }
                errorMessage={
                  (paymentMethodErrors as any)?.accountPaymentMethodName
                    ?.message
                }
                onChange={(value: string) => {
                  setPaymentMethod({
                    ...paymentMethod,
                    accountPaymentMethodName: value,
                  });
                }}
                onBlur={(value: string) =>
                  requiredValidation({
                    value,
                    name: "accountPaymentMethodName",
                    msg: t("frontend.account.payment_method.required"),
                  })
                }
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <TextInput
                name={paymentMethod.nameOnCreditCard}
                label={t("frontend.account.payment_method.name")}
                value={paymentMethod.nameOnCreditCard}
                isError={!!(paymentMethodErrors as any)?.nameOnCreditCard}
                errorMessage={
                  (paymentMethodErrors as any)?.nameOnCreditCard?.message
                }
                onChange={(value: string) => {
                  setPaymentMethod({
                    ...paymentMethod,
                    nameOnCreditCard: value,
                  });
                }}
                onBlur={(value: string) =>
                  requiredValidation({
                    value,
                    name: "nameOnCreditCard",
                    msg: t("frontend.account.payment_method.name_required"),
                  })
                }
              />
            </div>
            <div className='col-md-6'>
              <TextInput
                name={paymentMethod.creditCardNumber}
                label={t("frontend.account.payment_method.ccn")}
                value={paymentMethod.creditCardNumber}
                isError={!!(paymentMethodErrors as any)?.creditCardNumber}
                errorMessage={
                  (paymentMethodErrors as any)?.creditCardNumber?.message
                }
                onChange={(value: string) => {
                  if (
                    (/^-?\d+$/.test(value) && value.length <= 19) ||
                    value === ""
                  ) {
                    setPaymentMethod({
                      ...paymentMethod,
                      creditCardNumber: value,
                    });
                  }
                }}
                onBlur={(value: string) => {
                  Yup.string()
                    .min(13)
                    .max(19)
                    .validate(value, { abortEarly: false })
                    .then(() => {
                      let newErrors = { ...paymentMethodErrors };
                      delete (newErrors as any).creditCardNumber;
                      setPaymentMethodErrors(newErrors);
                    })
                    .catch((err) => {
                      setPaymentMethodErrors(
                        err.inner.reduce(
                          (acc: any, { message }: { message: string }) => {
                            return {
                              ...acc,
                              creditCardNumber: {
                                path: "creditCardNumber",
                                message,
                              },
                            };
                          },
                          paymentMethodErrors
                        )
                      );
                    });
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-3'>
              <div className='form-group'>
                <label htmlFor='expirationMonth'>
                  {t("frontend.account.payment_method.expiration_month")}
                </label>
                <SwSelect
                  id='expirationMonth'
                  value={paymentMethod.expirationMonth}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPaymentMethod({
                      ...paymentMethod,
                      expirationMonth: e.target.value,
                    });
                  }}
                  options={months}
                />
              </div>
            </div>
            <div className='col-md-3'>
              <div className='form-group'>
                <label htmlFor='expirationYear'>
                  {t("frontend.account.payment_method.expiration_year")}
                </label>
                <SwSelect
                  id='expirationYear'
                  value={paymentMethod.expirationYear}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPaymentMethod({
                      ...paymentMethod,
                      expirationYear: e.target.value,
                    });
                  }}
                  options={years}
                />
              </div>
            </div>
            <div className='col-md-6'>
              <TextInput
                name={paymentMethod.securityCode}
                label={t("frontend.account.payment_method.cvv")}
                value={paymentMethod.securityCode}
                isError={!!(paymentMethodErrors as any)?.securityCode}
                errorMessage={
                  (paymentMethodErrors as any)?.securityCode?.message
                }
                onChange={(value: string) => {
                  if (
                    (/^-?\d+$/.test(value) && value.length < 5) ||
                    value === ""
                  ) {
                    setPaymentMethod({
                      ...paymentMethod,
                      securityCode: value,
                    });
                  }
                }}
                onBlur={(value: string) => {
                  Yup.string()
                    .min(3)
                    .max(4)
                    .validate(value, { abortEarly: false })
                    .then(() => {
                      let newErrors = { ...paymentMethodErrors };
                      delete (newErrors as any).securityCode;
                      setPaymentMethodErrors(newErrors);
                    })
                    .catch((err) => {
                      setPaymentMethodErrors(
                        err.inner.reduce(
                          (acc: any, { message }: { message: string }) => {
                            return {
                              ...acc,
                              securityCode: { path: "securityCode", message },
                            };
                          },
                          paymentMethodErrors
                        )
                      );
                    });
                }}
              />
            </div>

            {paymentMethod.creditCardNumber.length > 2 && (
              <div className='row mt-4'>
                <div className='col-sm-12'>
                  <div className='col-md-6 pl-0'>
                    <div className='form-group'>
                      <label htmlFor='accountAddressID'>
                        Credit Card Billing Address
                      </label>
                      <SwRadioSelect
                        errorMsg={billingAccountAddressIDError}
                        onChange={(value: string) => {
                          setBillingAccountAddressIDError(null);
                          setBillingAccountAddressID(value);
                          setBillingAddress({
                            ...initialBillingAddress,
                            countryCode,
                          });
                          setShowNewAddressForm(false);
                        }}
                        options={accountAddresses.map(
                          ({
                            accountAddressName,
                            accountAddressID,
                            address: { streetAddress },
                          }: UserAccountAddress) => {
                            return {
                              name: `${accountAddressName} - ${streetAddress}`,
                              value: accountAddressID,
                            };
                          }
                        )}
                        selectedValue={billingAccountAddressID}
                        label={""}
                      />
                      {!showNewAddressForm && (
                        <button
                          className='btn btn-secondary mt-2 rounded-0'
                          onClick={(e) => {
                            setBillingAccountAddressIDError(null);
                            setBillingAccountAddressID("");
                            setBillingAddress({
                              ...initialBillingAddress,
                              countryCode,
                            });
                            setShowNewAddressForm(true);
                          }}
                        >
                          {t("frontend.account.address.add")}
                        </button>
                      )}
                    </div>
                  </div>
                  {showNewAddressForm && (
                    <AccountAddressForm
                      billingAddress={billingAddress}
                      setBillingAddress={setBillingAddress}
                      billingAddressErrors={billingAddressErrors}
                      setBillingAddressErrors={setBillingAddressErrors}
                    />
                  )}
                </div>
              </div>
            )}
            <div className='d-flex flex-wrap justify-content-end mt-2 gap-4'>
              <Link
                className='btn btn-secondary rounded-0 mt-4 col'
                to='/my-account/profile'
              >
                Cancel
              </Link>
              <Button
                disabled={isFetching}
                isLoading={isFetching}
                label='Save Card'
                classList='btn btn-primary rounded-0 mt-4 col'
                onClick={() => {
                  if (verifyOnSubmit()) {
                    setFetching(true);
                    saveCardToAccount();
                  }
                }}
              />
            </div>
          </div>
        </div>
      </form>
    </AccountLayout>
  );
};

export { CreateOrEditAccountPaymentMethod };
