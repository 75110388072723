import { useDispatch } from "react-redux";
import { deletePaymentMethod } from "@ultracommerce/react-storefront/global";
import { useToggle } from "../../../common/hooks/useToggle";
import { WarningPopup } from "../../../common/WarningPopup/WarningPopup";

type Props = {
  accountPaymentMethodID: string;
  creditCardLastFour: string;
  accountPaymentMethodName: string;
  className?: string;
};

const PaymentMethodItem = ({
  accountPaymentMethodID,
  accountPaymentMethodName,
  creditCardLastFour,
  className,
}: Props) => {
  const dispatch = useDispatch();

  const {
    toggleOff: closePopup,
    isToggledOn: isPopupOPen,
    toggleOn: openPopup,
  } = useToggle();

  const removePaymentMethod = () =>
    dispatch(deletePaymentMethod(accountPaymentMethodID) as any);

  return (
    <div className={`${className} border p-4`}>
      <div className='d-flex justify-content-between align-items-center'>
        <h4 className='pt-0'>{accountPaymentMethodName}</h4>
        <div className='d-flex align-items-center gap-2'>
          <button className='border-0 bg-transparent' onClick={openPopup}>
            <i className='bi bi-trash cursor-pointer'></i>
          </button>
        </div>
      </div>

      <p className='mt-2'>Credit card ending in *{creditCardLastFour}</p>
      <WarningPopup
        closePopup={closePopup}
        action={removePaymentMethod}
        isOpen={isPopupOPen}
        title={`Are you sure you want to delete ${accountPaymentMethodName}?`}
      />
    </div>
  );
};
export { PaymentMethodItem };
