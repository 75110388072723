import { useNavigate } from "react-router";
import { CreateOrEditAccountAddressModel } from "./CreateOrEditAccountAddress.componentModel";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewAccountAddress,
  deleteAccountAddress,
  getDefaultCountry,
  getStateCodeOptionsByCountryCode,
  updateAccountAddress,
} from "@ultracommerce/react-storefront/global";
import { Address } from "@/types/addresses";
import { useCallback, useEffect } from "react";
import { getCountryCodeDropdown, getStateCodeDropdown } from "./utils";
import { useCreateOrEditAccountAddress } from "./hooks/useCreateOrEditAccountAddress";

const REDIRECT_PATH = "/my-account/profile";

type Props = {
  path: string;
};

export const useComponentData = ({ path }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const defaultCountryCode: string = useSelector(getDefaultCountry);

  const { countryCodeOptions, stateCodeOptions, isEdit, shippingAddress } =
    useCreateOrEditAccountAddress(path);

  const getCountryStates = useCallback(
    (country: string) =>
      dispatch<any>(getStateCodeOptionsByCountryCode(country)),
    [dispatch]
  );

  useEffect(() => {
    getCountryStates(shippingAddress?.countryCode || defaultCountryCode);
  }, [
    dispatch,
    shippingAddress?.countryCode,
    shippingAddress,
    getCountryStates,
    defaultCountryCode,
  ]);

  const onAddNewAccountAddress = useCallback(
    async (address: Address) => {
      const response = await dispatch<any>(
        addNewAccountAddress({
          ...address,
          name: `${address?.firstName} ${address?.lastName}`,
        })
      );
      if (response.isSuccess()) navigate(REDIRECT_PATH);
    },
    [dispatch, navigate]
  );

  const onUpdateAccountAddress = useCallback(
    async (address: Address) => {
      const response = await dispatch<any>(
        updateAccountAddress({
          ...address,
          name: `${address?.firstName} ${address?.lastName}`,
        })
      );
      if (response.isSuccess()) navigate(REDIRECT_PATH);
    },
    [dispatch, navigate]
  );

  const onDeleteAccountAddress = useCallback(async () => {
    const response = await dispatch<any>(
      deleteAccountAddress(shippingAddress?.accountAddressID)
    );
    if (response.isSuccess()) navigate(REDIRECT_PATH);
  }, [shippingAddress?.accountAddressID, dispatch, navigate]);

  return {
    componentData: new CreateOrEditAccountAddressModel({
      shippingAddress,
      countryCodeDropdown: getCountryCodeDropdown(countryCodeOptions),
      isEdit,
      stateCodeDropdown: getStateCodeDropdown(stateCodeOptions),
      heading: "",
      defaultAddress: {
        accountAddressID: "",
        accountAddressName: "",
        additionalInformation: "",
        addressID: "",
        city: "",
        company: "",
        countryCode: defaultCountryCode,
        emailAddress: "",
        phoneNumber: "",
        street2Address: "",
        streetAddress: "",
        firstName: "",
        lastName: "",
        postalCode: "",
        stateCode: stateCodeOptions[defaultCountryCode]?.length
          ? stateCodeOptions[defaultCountryCode][0]?.value
          : "",
        name: "",
      },
      isFormDataReady:
        Object.keys(stateCodeOptions).length && countryCodeOptions.length,
    }),
    componentServices: {
      onAddNewAccountAddress,
      onUpdateAccountAddress,
      onDeleteAccountAddress,
      getCountryStates,
    },
  };
};
