import { Link } from "react-router-dom";

import { RepDashboardModel } from "./RepDashboard.componentModel";
import { useOrderOverviewTable } from "./hooks/useOrderOverviewTable";
import { Table } from "../common/Table/Table";
import OrderDetailsComponent from "../OrderDetails/OrderDetails.component";
import { Order } from "@/types/order";

type Props = {
  componentData: RepDashboardModel;
};

export const RepDashboardComponentView = ({ componentData }: Props) => {
  const table = useOrderOverviewTable({ data: componentData.orders });

  return (
    <div>
      <h2 className='bg-white p-4'>Welcome back, {componentData.username}</h2>

      <div className='bg-white mt-4 p-4'>
        <div className='d-flex align-items-center'>
          <h3 className='me-auto mb-0 pt-0'>Recent Orders</h3>
          <Link to='/my-account/orders'>View Order History and Details</Link>
        </div>

        <Table
          table={table}
          renderExtended={(order: Order) => (
            <OrderDetailsComponent id={order.orderID} />
          )}
        />
      </div>
    </div>
  );
};
