import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import {
  useElementContext,
  getDefaultCountry,
  getStateCodeOptionsByCountryCode,
  getCountriesAndAddressOptions,
} from "@ultracommerce/react-storefront/global";
import { RootStateType } from "@/types/state";
import { Address } from "@/types/addresses";
import { useAccountAddresses } from "../../../../common/hooks/useAccountAddresses";
import { PaymentMethodBillingAddress } from "./PaymentMethodBillingAddress/PaymentMethodBillingAddress";
import { getValidCountries } from "../../../../common/utils/getValidCountries";

type Props = {
  isEdit: boolean;
  onSave: (address: Address & { saveAddress: boolean }) => void;
  onCancel: () => void;
};

const BillingAddress = ({ isEdit = false, onSave, onCancel }: Props) => {
  const cartState = useSelector((state: RootStateType) => state.cart);
  const dispatch = useDispatch();
  const defaultCountryCode = useSelector(getDefaultCountry);

  const countries = useSelector(
    (state: RootStateType) => state.content.countries
  );
  const stateCodeOptions = useSelector(
    (state: RootStateType) => state.content.stateCodeOptions
  );

  const { states = [] } = countries[defaultCountryCode] || {};

  const initialBillingAddress =
    isEdit && cartState.orderPayments.length > 0
      ? {
          countryCode: cartState.orderPayments?.at(0)?.billingAddress,
          name: cartState.orderPayments?.at(0)?.billingAddress.name,
          company: cartState.orderPayments?.at(0)?.billingAddress.company,
          phoneNumber:
            cartState.orderPayments?.at(0)?.billingAddress.phoneNumber,
          emailAddress:
            cartState.orderPayments?.at(0)?.billingAddress.emailAddress,
          streetAddress:
            cartState.orderPayments?.at(0)?.billingAddress.streetAddress,
          street2Address:
            cartState.orderPayments?.at(0)?.billingAddress.street2Address,
          city: cartState.orderPayments?.at(0)?.billingAddress.city,
          stateCode: cartState.orderPayments?.at(0)?.billingAddress.stateCode,
          postalCode: cartState.orderPayments?.at(0)?.billingAddress.postalCode,
          accountAddressName:
            cartState.orderPayments?.at(0)?.billingAddress?.accountAddressName,
        }
      : {
          countryCode: defaultCountryCode || "",
          name: "",
          company: "",
          phoneNumber: "",
          emailAddress: "",
          streetAddress: "",
          street2Address: "",
          city: "",
          stateCode: states[0]?.value,
          postalCode: "",
          accountAddressName: "",
        };

  const billingAddress = {
    ...initialBillingAddress,
    countryCode: defaultCountryCode,
  } as Address;

  useEffect(() => {
    if (!Object.keys(countries)?.length)
      dispatch(getCountriesAndAddressOptions() as any);
    dispatch(getStateCodeOptionsByCountryCode(defaultCountryCode) as any);
  }, [countries, defaultCountryCode, dispatch]);

  if (!Object.keys(countries)?.length) return null;

  return (
    <PaymentMethodBillingAddress
      billingAddress={billingAddress}
      countries={getValidCountries(countries)}
      states={stateCodeOptions}
      onCancel={onCancel}
      onSave={onSave}
    />
  );
};

type PaymentAddressSelectorProps = {
  isEdit: boolean;
  onSelect: (address: string) => void;
  onSave: (address: Address) => void;
  selectedAccountID: string;
};

const PaymentAddressSelector = ({
  isEdit,
  onSelect,
  onSave,
  selectedAccountID,
}: PaymentAddressSelectorProps) => {
  const {
    CommonModule: { SwRadioSelect },
  } = useElementContext();
  const { shippingAddresses } = useAccountAddresses();
  const [showAddress, setShowAddress] = useState(false);
  const { t } = useTranslation();
  const cartState = useSelector((state: RootStateType) => state.cart);
  if (showAddress || shippingAddresses.length === 0) {
    selectedAccountID = "new";
  }
  useEffect(() => {
    if (
      shippingAddresses.length === 0 ||
      cartState.orderPayments?.at(0)?.billingAddress
    ) {
      setShowAddress(true);
    }
  }, [shippingAddresses.length, cartState.orderPayments]);

  return (
    <>
      <h2 className='h6 pt-1 pb-2 mb-2 border-bottom'>
        Payment Method Billing Address
      </h2>
      {shippingAddresses && (
        <div className='row mb-2'>
          <div className='col-sm-12'>
            {!showAddress && (
              <SwRadioSelect
                options={shippingAddresses.map(
                  ({
                    accountAddressName,
                    accountAddressID,
                    streetAddress,
                  }: Address) => {
                    return {
                      name: `${accountAddressName} - ${streetAddress}`,
                      value: accountAddressID,
                    };
                  }
                )}
                onChange={(value: string): void => {
                  setShowAddress(false);
                  onSelect(value);
                }}
                //@ts-ignore
                customLabel={t("frontend.checkout.receive_option")}
                selectedValue={selectedAccountID}
                displayNew={true}
              />
            )}
          </div>
        </div>
      )}
      {showAddress && (
        <BillingAddress
          isEdit={isEdit}
          onSave={(values) => {
            setShowAddress(false);
            onSave(values);
          }}
          onCancel={() => {
            setShowAddress(false);
          }}
        />
      )}
      {!showAddress && (
        <button
          className='btn btn-secondary mb-3'
          onClick={() => setShowAddress(true)}
        >
          New Address
        </button>
      )}
    </>
  );
};

export { PaymentAddressSelector };
