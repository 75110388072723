import { useMemo } from "react";
import { Link } from "react-router-dom";

import { Order } from "@/types/order";
import {
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useFormatCurrency } from "@ultracommerce/react-storefront/global";
import { formatDate } from "../../common/utils/formatDate";
import { useReorder } from "../../common/hooks/useReorder";
import { Loader } from "../../common/Loader/Loader";
import { SortIcon } from "../../common/SortIcon/SortIcon";

const columnHelper = createColumnHelper<Order>();

export const useOrderOverviewTable = ({ data }: { data: Order[] }) => {
  const [formatCurrency] = useFormatCurrency({});

  const { reorder, isReordering } = useReorder();

  const columns = useMemo(
    () => [
      columnHelper.accessor("orderPayments_purchaseOrderNumber", {
        cell: ({ table, row, getValue }) => {
          const isExpanded = row.getIsExpanded();
          const toggleExpanded = row.getToggleExpandedHandler();
          const clickHandler = (event: React.MouseEvent) => {
            event.preventDefault();
            !isExpanded && table.toggleAllRowsExpanded(false);
            toggleExpanded();
          };
          const value = getValue();
          const formattedValue = value.trim() || "No PO";

          return row.getCanExpand() ? (
            <Link
              onClick={clickHandler}
              to={`/my-account/orders/${row.original.orderID}`}
              className='text-decoration-none'
            >
              {formattedValue} <i className='bi bi-chevron-right'></i>
            </Link>
          ) : (
            formattedValue
          );
        },
        header: "PO number",
      }),
      columnHelper.accessor("orderOpenDateTime", {
        cell: (v) => formatDate(v.getValue()),
        header: ({ column }) => (
          <span onClick={column.getToggleSortingHandler()}>
            Date <SortIcon sortDirection={column.getIsSorted()} />
          </span>
        ),
        sortDescFirst: true,
        sortingFn: "datetime",
      }),
      columnHelper.accessor("paymentMethod", {
        cell: (v) => v.getValue(),
        header: () => <span>Status</span>,
      }),
      columnHelper.accessor("calculatedTotal", {
        cell: (v) => formatCurrency(v.getValue()),
        header: ({ column }) => (
          <span onClick={column.getToggleSortingHandler()}>
            Total Price <SortIcon sortDirection={column.getIsSorted()} />
          </span>
        ),
      }),
      columnHelper.accessor("reorderLabel", {
        cell: ({ row, getValue }) => {
          const isExpanded = row.getIsExpanded();
          const value = getValue();
          return isExpanded ? (
            <button
              onClick={() => reorder(row.original.orderID)}
              className='bg-transparent border-0 d-flex align-items-center gap-1'
            >
              {!isReordering ? (
                <i className='bi bi-repeat'></i>
              ) : (
                <Loader size='sm' />
              )}
              {value}
            </button>
          ) : null;
        },
        header: () => null,
      }),
    ],
    [formatCurrency, isReordering, reorder]
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowCanExpand: () => true,
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: process.env.NODE_ENV === "development",
  });

  return table;
};
