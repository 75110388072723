import {
  useContentContext,
  useElementContext,
  useUtilities,
} from "@ultracommerce/react-storefront/global";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { RootStateType } from "@/types/state";
import { Navigate } from "react-router";

const redirects = ["/"] as const;
type RedirectPath = (typeof redirects)[number];

const REDIRECTS = {
  "/": <Navigate to='/my-account/overview' replace={true} />,
} as const satisfies Record<RedirectPath, JSX.Element>;

const isRedirectPath = (path: string) =>
  redirects.find((redirectPath) => redirectPath === path);

type RenderDynamicContent = {
  renderDynamicContent: () => JSX.Element;
};

type RenderDynamicContentFn = (content: RenderDynamicContent) => JSX.Element;

type Props = {
  children: JSX.Element | RenderDynamicContentFn;
};

const DynamicPage = ({ children }: Props) => {
  const {
    CommonModule: { Layout, DynamicComponent, DynamicContent },
  } = useElementContext();

  const pageData = useContentContext();

  let { eventHandlerForWSIWYG } = useUtilities();

  const siteName = useSelector(
    (state: RootStateType) => state.configuration.site.siteName
  );
  const header = useSelector(
    (state: RootStateType) => state.configuration.site?.header
  );
  const footer = useSelector(
    (state: RootStateType) => state.configuration.site?.footer
  );

  if (!!pageData?.is500 || !!pageData?.is404) {
    return (
      <div className={"DynamicPage p-0"}>
        <div className='page-title-overlap bg-lightgray pt-4'>
          <div className='container d-lg-flex justify-content-between py-2 py-lg-3'>
            <div className='order-lg-1 pr-lg-4 text-center'>
              <h1 className='h3 text-dark mb-0 font-accent text-center'>
                {pageData?.contentHeading?.trim()}
              </h1>
            </div>
          </div>
        </div>
        <div className='container p-4 p-lg-5 mb-5'>
          <div
            onClick={eventHandlerForWSIWYG}
            dangerouslySetInnerHTML={{ __html: pageData?.contentBody }}
          />
          {typeof children !== "function" ? children : null}
        </div>
      </div>
    );
  }

  const renderDynamicContent = () =>
    isRedirectPath(window.location.pathname) ? (
      REDIRECTS[window.location.pathname as RedirectPath]
    ) : (
      <DynamicContent
        key={pageData?.urlTitlePath}
        contentElements={pageData?.contentElements}
      />
    );

  return (
    <Layout>
      {!!pageData?.settings?.contentHTMLTitleString && (
        <Helmet title={pageData.settings.contentHTMLTitleString} />
      )}
      {!pageData?.settings?.contentHTMLTitleString && (
        <Helmet
          title={[siteName, pageData?.title].filter((e) => e).join(" | ")}
        />
      )}

      <DynamicComponent el={header} key={"element-header"} />
      {typeof children === "function" ? (
        children({ renderDynamicContent })
      ) : (
        <>
          {renderDynamicContent()}
          {children}
        </>
      )}

      <DynamicComponent el={footer} key={"element-footer"} />
    </Layout>
  );
};
export default DynamicPage;
