import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useGetAllOrders } from "@ultracommerce/react-storefront/global";
import { RepDashboardModel } from "./RepDashboard.componentModel";
import { Order } from "@/types/order";
import { RootStateType } from "@/types/state";
import { getPaymentMethod } from "../common/utils/paymentMethods";

const PAGE_SIZE = 10;

export const useComponentData = () => {
  const [request, setRequest] = useGetAllOrders();

  const { firstName, company, eligiblePaymentMethods } = useSelector(
    (state: RootStateType) => state.userReducer
  );
  useEffect(() => {
    // @ts-ignore
    setRequest((request: Request) => ({
      ...request,
      isFetching: true,
      isLoaded: false,
      makeRequest: true,
      params: {
        currentPage: 1,
        pageRecordsShow: PAGE_SIZE,
      },
    }));
  }, [setRequest]);

  const componentData = useMemo(() => {
    return new RepDashboardModel({
      // @ts-ignore
      orders: request.data.ordersOnAccount as Order[],
      filters: [],
      searchQuery: "",
      username: firstName || company,
      paymentMethod: getPaymentMethod(eligiblePaymentMethods),
    });
  }, [request, firstName, company, eligiblePaymentMethods]);

  return {
    componentData,
  };
};
