import { Field, Form, Formik } from "formik";
import { useEditAccountInformation } from "./hooks/useEditAccountInformation";
import { Link } from "react-router-dom";

export const EditAccountInformation = () => {
  const { account, updateAccount } = useEditAccountInformation();

  if (!account?.email) return null;

  return (
    <div className='bg-white p-4'>
      <div className='d-flex justify-content-between align-items-center'>
        <h2 className='pt-0 mb-4'>Account Information</h2>
      </div>

      <Formik
        initialValues={account}
        onSubmit={(account) => updateAccount(account)}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className='d-flex gap-4'>
              <div className='col'>
                <label className='h4 pt-0' htmlFor='firstname'>
                  First Name
                </label>
                <Field
                  className='border p-2 form-control rounded-0'
                  name='firstname'
                  id='firstname'
                />
              </div>
              <div className='col'>
                <label className='h4 pt-0' htmlFor='lastname'>
                  Last Name
                </label>
                <Field
                  className='border p-2 form-control rounded-0'
                  name='lastname'
                  id='lastname'
                />
              </div>
            </div>

            <div>
              <label className='h4' htmlFor='email'>
                Email
              </label>
              <Field
                className='border p-2 form-control rounded-0'
                name='email'
                id='email'
                disabled
              />
            </div>
            <div>
              <label className='h4' htmlFor='billingEmail'>
                Billing Email
              </label>
              <Field
                className='border p-2 form-control rounded-0'
                name='billingEmail'
                id='billingEmail'
                disabled
              />
            </div>
            <div>
              <label className='h4' htmlFor='salesforeId'>
                Salesforce ID
              </label>
              <Field
                className='border p-2 form-control rounded-0'
                name='salesforeId'
                id='salesforeId'
                disabled
              />
            </div>
            <div>
              <label className='h4' htmlFor='customerId'>
                Customer ID
              </label>
              <Field
                className='border p-2 form-control rounded-0'
                value={account.customerId as string}
                name='customerId'
                disabled
              />
            </div>

            <div className='d-flex gap-4'>
              <div className='col'>
                <label className='h4' htmlFor='company'>
                  Company
                </label>
                <Field
                  className='border p-2 form-control rounded-0'
                  name='company'
                  id='company'
                  disabled
                />
              </div>
              <div className='col'>
                <label className='h4' htmlFor='website'>
                  Website
                </label>
                <Field
                  className='border p-2 form-control rounded-0'
                  name='website'
                  id='website'
                />
              </div>
            </div>
            <div className='mt-4'>
              <label className='h4 pt-0' htmlFor='communiactionPreferences'>
                Communication Preferences
              </label>
              <div className='d-flex align-items-start gap-2'>
                <Field
                  type='checkbox'
                  name='communiactionPreferences'
                  value='communiactionPreferences'
                  checked={values.communiactionPreferences}
                  onChange={({
                    target: { checked },
                  }: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue("communiactionPreferences", checked)
                  }
                />
                <div>
                  <span className='text-danger'>*</span>
                  Manage communication preferences... check this box if lorem
                  ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                  enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum
                </div>
              </div>
            </div>
            <div className='mt-5 d-flex gap-4'>
              <Link
                to='/my-account/profile'
                className='btn btn-secondary col p-2 rounded-0'
              >
                CANCEL
              </Link>
              <button
                className='btn btn-primary col p-2 rounded-0'
                type='submit'
              >
                SAVE CHANGES
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
