import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import CartModel from "./Cart.componentModel";
import {
  applyPromoCode,
  clearCartData,
  disableInteractionSelector,
  getCart,
  removeItem,
  updateItemQuantity,
} from "@ultracommerce/react-storefront/global";
import { RootStateType } from "@/types/state";
import { Cart } from "@/types/cart";

export const useComponentData = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cart: Cart = useSelector((state: RootStateType) => state.cart);

  const disableInteraction: boolean = useSelector(disableInteractionSelector);
  const [isRemovingItem, setRemovingItem] = useState(false);

  const componentData = useMemo(
    () => new CartModel({ cart, isRemovingItem, disableInteraction }),
    [cart, isRemovingItem, disableInteraction]
  );

  useEffect(() => {
    dispatch(getCart() as any);
  }, [dispatch]);

  const onRemoveItem = useCallback(
    async (itemId: string) => {
      setRemovingItem(true);
      await dispatch(removeItem(itemId) as any);
      setRemovingItem(false);
    },
    [dispatch]
  );

  const onUpdateQty = useCallback(
    (orderItemID: string, itemCount: number) =>
      dispatch(updateItemQuantity(orderItemID, itemCount) as any),
    [dispatch]
  );

  const onClearCartData = useCallback(
    () => dispatch(clearCartData() as any),
    [dispatch]
  );

  const onApplyCode = useCallback(
    (promoCode: string, setPromoCode: (code: string) => void) => {
      dispatch(
        applyPromoCode(promoCode, t("frontend.cart.promo_code_applied")) as any
      );
      setPromoCode("");
    },
    [dispatch, t]
  );

  return {
    componentData,
    componentService: {
      onClearCartData,
      onRemoveItem,
      onUpdateQty,
      onApplyCode,
    },
  };
};
