import {
  deleteAccountAddress,
  getAllAccountAddresses,
  getPrimaryAddress,
} from "@ultracommerce/react-storefront/global";
import { useDispatch, useSelector } from "react-redux";
import {
  UserAccountAddress,
  UserAccountPrimaryAddress,
} from "@/types/addresses";
import { useCallback } from "react";
import { getAddresses } from "../utils/getAddresses";

export const useAccountAddresses = () => {
  const dispatch = useDispatch();

  const accountAddresses = useSelector(
    getAllAccountAddresses
  ) as UserAccountAddress[];

  const accountPrimaryAddressID = (
    useSelector(getPrimaryAddress) as UserAccountPrimaryAddress
  )?.accountAddressID;

  const onDeleteAccountAddress = useCallback(
    async (id: string) => {
      dispatch<any>(deleteAccountAddress(id));
    },
    [dispatch]
  );

  const { shippingAddresses, billingAddress } = getAddresses({
    addresses: accountAddresses,
    primaryAddressID: accountPrimaryAddressID,
  });

  return {
    shippingAddresses,
    billingAddress,
    onDeleteAccountAddress,
  };
};
