import { RootStateType } from "@/types/state";
import { addMultipleItemsToCart } from "../../../actions/addMultipleItemsToCart";
import { OrderItem } from "@/types/order";
import { useGetOrderDetails } from "@ultracommerce/react-storefront/global";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

const SHOPPING_CART_PATH = "/shopping-cart";

export const useReorder = () => {
  const [request, setRequest] = useGetOrderDetails();
  const [orderID, setOrderID] = useState("");

  const { isFetching } = useSelector((state: RootStateType) => state.cart);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!orderID) return;
    // @ts-ignore
    setRequest((request) => ({
      ...request,
      isFetching: true,
      isLoaded: false,
      makeRequest: true,
      params: {
        orderID,
      },
    }));
  }, [orderID, setRequest]);

  const addItemsToCart = useCallback(
    async ({ orderItems }: { orderItems: OrderItem[] }) => {
      const { sku_skuIDs, quantities } = orderItems.reduce<{
        quantities: number[];
        sku_skuIDs: string[];
      }>(
        (acc, item) => {
          acc.quantities.push(item.quantity);
          acc.sku_skuIDs.push(item.sku_skuID);
          return acc;
        },
        { quantities: [], sku_skuIDs: [] }
      );

      const response = await dispatch(
        addMultipleItemsToCart({
          skuIDs: sku_skuIDs.join(),
          quantities: quantities.join(),
        }) as any
      );

      if (
        response.isSuccess() &&
        !Object.keys(response.success()?.errors || {}).length
      )
        navigate(SHOPPING_CART_PATH);

      setOrderID("");
    },
    [dispatch, navigate]
  );

  useEffect(() => {
    //@ts-ignore
    const { isFetching, isLoaded, data } = request;
    const isDataReady = !isFetching && isLoaded && !!data;

    if (!isDataReady) return;

    addItemsToCart(data);
  }, [addItemsToCart, dispatch, navigate, request]);

  const reorder = (id: string) => {
    setOrderID(id);
  };

  return {
    reorder,
    isReordering: isFetching,
  };
};
