type Props = {
  title: string;
  value: string | null;
  className?: string;
};

export const AccountField = ({ title, value, className }: Props) => {
  if (!value?.trim()) return null;

  return (
    <div className={className}>
      <p className='py-1 mb-0 text-break h6'>
        <strong>{title}:</strong> {value}
      </p>
    </div>
  );
};
