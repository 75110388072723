import {
  useElementContext,
  useUtilities,
} from "@ultracommerce/react-storefront/global";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

export interface MenuItemProps {
  linkLabel?: string;
  linkUrl?: string;
  specialMenuType?: "user-account" | "user-register" | "user-logout";
  cssClass?: string;
  childItem?: boolean;
  showAllLink?: string;
  urlTarget?: "_blank" | "_self";
  menuStyle?: string;
  children?: ReactNode[];
}

const MenuItem = ({
  linkLabel = "",
  linkUrl = "",
  children,
  specialMenuType,
  cssClass,
  childItem,
  showAllLink,
  urlTarget,
  menuStyle,
}: MenuItemProps) => {
  const {
    CommonModule: {
      AccountBubble,
      RegisterSpecialMenu,
      LogoutSpecialMenu,
      ExternalLink,
    },
  } = useElementContext();
  const { eventHandlerForWSIWYG } = useUtilities();

  const [linkSelected, setLinkSelected] = useState(false);
  const rapperRef = useRef<HTMLDivElement>(null);

  const { pathname } = useLocation();
  useEffect(() => {
    if (linkSelected) setLinkSelected(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (!linkSelected) return;
    function handleClickOutside(event: MouseEvent) {
      if (
        rapperRef.current &&
        !rapperRef.current.contains(event.target as Node)
      ) {
        setLinkSelected(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [rapperRef, linkSelected]);

  const isActiveLink = pathname === linkUrl;

  return (
    <li key={linkLabel} className={`nav-item dropdown ${menuStyle || ""}`}>
      {!children?.length && (
        <>
          {!specialMenuType && (
            <ExternalLink
              className={`nav-link p-2 ${cssClass ? cssClass : ""} ${
                isActiveLink ? "text-decoration-underline" : ""
              }`}
              linkUrl={linkUrl}
              target={urlTarget}
              {...(urlTarget === "_blank"
                ? { rel: "noreferrer noopener" }
                : {})}
            >
              {linkLabel}
            </ExternalLink>
          )}
          {specialMenuType === "user-account" && (
            <AccountBubble cssClass={`nav-link ${cssClass || ""}`} />
          )}
          {specialMenuType === "user-register" && (
            <RegisterSpecialMenu
              cssClass={`nav-link ${cssClass || ""}`}
              linkLabel={linkLabel}
            />
          )}
          {specialMenuType === "user-logout" && (
            <LogoutSpecialMenu
              cssClass={`nav-link ${cssClass || ""}`}
              linkLabel={linkLabel}
            />
          )}
        </>
      )}
      {!!children?.length && (
        <>
          <span
            className={`nav-link dropdown-toggle p-2 d-flex align-items-center ${
              specialMenuType ? specialMenuType : ""
            } ${cssClass ? cssClass : ""}`}
            role='button'
            onClick={() => setLinkSelected(!linkSelected)}
          >
            {linkLabel}
          </span>
          <ExternalLink
            className={`nav-link nav-link--hierarchical p-2 ${
              cssClass ? cssClass : ""
            } ${!showAllLink && !linkUrl ? "pe-none" : ""}`}
            linkUrl={showAllLink || linkUrl}
            target={urlTarget}
            {...(urlTarget === "_blank" ? { rel: "noreferrer noopener" } : {})}
          >
            {linkLabel}
          </ExternalLink>
          <div
            ref={rapperRef}
            className={`mega-menu dropdown-menu border pt-0 ${
              linkSelected && "show"
            } ${
              childItem
                ? "dropdown-menu--child w-100 m-0 top-0 bottom-0"
                : "top-100"
            }`}
          >
            {childItem && (
              <div className='menu-back-arrow w-100 ps-3 mt-3'>
                <i
                  ref={(node) => {
                    node?.style.setProperty("font-size", "1rem", "important");
                    node?.style.setProperty(
                      "-webkit-text-stroke",
                      "1px",
                      "important"
                    );
                  }}
                  className='bi bi-arrow-left'
                  role='button'
                  onClick={() => setLinkSelected(false)}
                ></i>
              </div>
            )}
            {showAllLink?.trim() && (
              <div className='nav-shop-all '>
                <ExternalLink linkUrl={showAllLink}>
                  {linkLabel}
                  <i className='bi bi-arrow-right ms-2'></i>
                </ExternalLink>
              </div>
            )}
            <ul className='mega-dropdown-list pt-2'>
              {children.map((menuPanel: any, index) => {
                menuPanel.props.el.childItem = true;
                return (
                  <div key={index} className='mega-dropdown-column'>
                    <div
                      className='widget widget-links'
                      onClick={eventHandlerForWSIWYG}
                    >
                      <ul className='navbar-nav'>{menuPanel}</ul>
                    </div>
                  </div>
                );
              })}
            </ul>
          </div>
        </>
      )}
    </li>
  );
};

export { MenuItem };
