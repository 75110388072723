type HubspotForm = {
  region: string;
  portalId: string;
  formId: string;
};

export type FormType = "rma" | "mup" | "cba";

type FormsConfig = Record<FormType, HubspotForm>;

export const FORMS_CONFIG: FormsConfig = {
  rma: {
    formId: "c9b02130-0ef7-41a0-91ac-a3dae0172463",
    portalId: "24202603",
    region: "na1",
  },
  mup: {
    formId: "00374577-4def-4e0c-8aa1-10ceb531ee98",
    portalId: "24202603",
    region: "na1",
  },
  cba: {
    formId: "0f8cbde7-4c7c-4183-b01a-ab49c92faf54",
    portalId: "24202603",
    region: "na1",
  },
};
