import { useCallback, useMemo, useState } from "react";

import {
  CartLineItemModel,
  CartLineItemProps,
} from "./CartLineItem.componentModel";

const useComponentData = (props: CartLineItemProps) => {
  const [itemCount, setItemCount] = useState(props.orderItem.quantity);
  const componentData = useMemo(
    () => new CartLineItemModel({ props, itemCount }),
    [props, itemCount]
  );

  const onRemoveItem = useCallback(() => {
    props.onRemoveItem?.(componentData.orderItem.orderItemID);
  }, [componentData.orderItem.orderItemID, props]);

  const onUpdateQty = useCallback(() => {
    props.onUpdateQty?.(
      componentData.orderItem.orderItemID,
      componentData.itemCount
    );
  }, [componentData.itemCount, componentData.orderItem.orderItemID, props]);

  return {
    componentData,
    componentService: {
      setItemCount,
      onUpdateQty,
      onRemoveItem,
    },
  };
};
export { useComponentData };
