import { AccountInformation } from "@/components/AccountInformation/AccountInformation.types";
import { mapAccount } from "../../../utils/mapAccount";
import { RootStateType } from "@/types/state";
import { UserAccount } from "@/types/userAccount";
import { getUser, updateUser } from "@ultracommerce/react-storefront/global";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

const ON_SUCCESS_REDIRECT = "/my-account/profile";

export const useEditAccountInformation = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const account = useSelector(
    (state: RootStateType) => state.userReducer
  ) as UserAccount;

  const updateAccount = (values: AccountInformation) =>
    dispatch(
      updateUser({
        firstName: values.firstname,
        lastName: values.lastname,
        username: values.email,
        salesforceCode: values.salesforeId,
        customerCode: values.customerId,
        company: values.company,
        website: values.website,
        emailConsent: values.communiactionPreferences,
      }) as any
    ).then(async (res: any) => {
      if (res.isSuccess()) {
        await dispatch(getUser() as any);
        navigate(ON_SUCCESS_REDIRECT);
      }
    });

  return {
    account: account ? mapAccount(account) : null,
    updateAccount,
  };
};
