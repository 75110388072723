export const SortIcon = ({
  sortDirection,
  isDisabled = false,
}: {
  sortDirection: false | "asc" | "desc";
  isDisabled?: boolean;
}) => {
  if (isDisabled) return null;

  if (sortDirection === false) {
    return <i className='bi bi-arrow-down-up'></i>;
  }

  return {
    asc: <i className='bi bi-arrow-up'></i>,
    desc: <i className='bi bi-arrow-down'></i>,
  }[sortDirection];
};
