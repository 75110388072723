import { CountryCodeOption, StateCodeOptions } from "@/types/addresses";

export const ALLOWED_COUNTRIES = ["US", "CA"] as const;

export type AllowedCountries = (typeof ALLOWED_COUNTRIES)[number];

export const isAllowedCountry = (
  country: string
): country is AllowedCountries =>
  ALLOWED_COUNTRIES.includes(country as AllowedCountries);

const PLEASE_SELECT_LABEL = "Please Select";

const PLEASE_SELECT_OPTION = {
  name: PLEASE_SELECT_LABEL,
  states: [],
  value: "",
};

export const getCountryCodeDropdown = (
  countryCodeOptions: CountryCodeOption[]
): CountryCodeOption[] => {
  const countriesDropwdown: CountryCodeOption[] = [PLEASE_SELECT_OPTION];

  const filteredCountryCodeOptions = countryCodeOptions.filter(
    ({ value }: CountryCodeOption) => isAllowedCountry(value)
  );

  countriesDropwdown.push(...filteredCountryCodeOptions);

  return countriesDropwdown;
};

export const getStateCodeDropdown = (
  stateCodeOptions: StateCodeOptions
): StateCodeOptions | null => {
  if (!Object.keys(stateCodeOptions).length) return null;

  const stateCodeDropdown: StateCodeOptions = {};

  for (const key in stateCodeOptions) {
    if (key)
      stateCodeDropdown[key] = [PLEASE_SELECT_OPTION, ...stateCodeOptions[key]];
  }

  return stateCodeDropdown;
};
