import { Order } from "@/types/order";

type Filter = {
  label: string;
  value: any;
};

export class RepDashboardModel {
  username: string;
  orders: Order[];
  filters: Filter[];
  selectedFilter: Filter | null;
  searchQuery: string | null;

  constructor({
    username,
    orders,
    filters,
    selectedFilter,
    searchQuery,
    paymentMethod,
  }: {
    username: string;
    orders?: Order[];
    filters: Filter[];
    selectedFilter?: Filter;
    searchQuery?: string;
    paymentMethod: string;
  }) {
    this.username = username;
    this.orders =
      orders?.map((order) => ({
        ...order,
        reorderLabel: "Reorder",
        paymentMethod,
      })) || [];
    this.filters = filters || [];
    this.selectedFilter = selectedFilter || filters[0];
    this.searchQuery = searchQuery ?? null;
  }
}
