import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { checkOutSteps, getCurrentStep } from "./steps";
import { getStepCss } from "./utils";

const StepsHeader = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const path = location.pathname.split("/").reverse()[0];
  const current = getCurrentStep(path);

  if (!current) return null;

  return (
    <ul className='nav nav-pills border nav-fill mb-4 p-2 rounded'>
      {checkOutSteps.map((step) => (
        <li className='nav-item' key={step.progress}>
          <span
            className={`nav-link link text-decoration-none ${getStepCss({
              stepIndex: step.progress,
              currentStepIndex: current.progress,
            })}`}
            key={step.progress}
            onClick={(e) => {
              navigate(step.link as string);
            }}
          >
            {t(step.name)}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default StepsHeader;
