import { PaginationState } from "./Table";

export const getPaginationLabel = ({
  pageIndex,
  pageSize,
  totalItems,
}: PaginationState) => {
  const startNumber = pageSize * pageIndex + 1;
  const endNumber = (pageIndex + 1) * pageSize;
  const isLastPage = (pageIndex + 1) * pageSize > totalItems;
  const hasPagination = totalItems > pageSize;

  if (pageIndex === 0 && !hasPagination)
    return `Showing  ${totalItems} out of ${totalItems}`;

  if (isLastPage || !hasPagination)
    return `Showing ${startNumber} to ${totalItems} out of ${totalItems}`;

  return `Showing ${startNumber} to ${endNumber} out of ${totalItems}`;
};
