import { CSSProperties, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  addPayment,
  disableInteractionSelector,
  eligiblePaymentMethodDetailSelector,
  fulfillmentSelector,
  getAllOrderPayments,
  getErrorMessage,
  orderPayment,
  removeOrderPayment,
  useCheckoutUtilities,
  useElementContext,
} from "@ultracommerce/react-storefront/global";
import { TermPayment } from "./components/TermPayment/TermPayment";
import { CreditCardPayment } from "./components/CreditCardPayment/CreditCardPayment";
import { RootStateType } from "@/types/state";
import { formatPoNumber, removePoPrefix } from "./utils";
import { CheckoutStep } from "../types";
import { PaymentMethod } from "@/types/cart";

type Props = {
  currentStep: CheckoutStep;
  cartState: any;
};

const PaymentSlide = ({ currentStep, cartState }: Props) => {
  const {
    CommonModule: {
      TillPayments,
      SlideNavigation,
      SwRadioSelect,
      Overlay,
      PaymentList,
      PayPalPayment,
      PayPalCommercePayment,
      GiftCardPayment,
    },
  } = useElementContext();
  const disableInteraction: boolean = useSelector(disableInteractionSelector);
  const fulfillment = useSelector(fulfillmentSelector);
  const orderRequirementsList = useSelector(
    (state: RootStateType) => state.cart.orderRequirementsList
  );
  const { poPrefix } = useSelector((state: RootStateType) => state.userReducer);

  const eligiblePaymentMethodDetails = useSelector(
    eligiblePaymentMethodDetailSelector
  ) as any;

  const { paymentMethod, purchaseOrderNumber } = useSelector(orderPayment) as {
    paymentMethod: PaymentMethod;
    purchaseOrderNumber: string;
  };

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>("");
  const [paymentMethodOnOrder, setPaymentMethodOnOrder] = useState<any>(false);
  const [editPaymentDetails, setEditPaymentDetails] = useState(false);
  const [isEditExistingPayment, setEditExistingPayment] = useState(false);
  const allPayments = useSelector(getAllOrderPayments);

  const [poNumber, setPoNumber] = useState("");

  const { isFetching } = useSelector((state: RootStateType) => state.cart);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    EXTERNAL_PAYMENT_CODE,
    CREDIT_CARD_CODE,
    GIFT_CARD_CODE,
    TERM_PAYMENT_CODE,
    PAYPAL_PAYMENT_CODE,
    CASH_PAYMENT_CODE,
    CHECK_PAYMENT_CODE,
    PAYPAL_COMMERCE_CODE,
    getPaymentMethodByIDFromList,
  } = useCheckoutUtilities();
  const creditCardPaymentObject = eligiblePaymentMethodDetails
    .filter(
      (paymentMethod: PaymentMethod) =>
        paymentMethod?.paymentMethodType === CREDIT_CARD_CODE
    )
    ?.at(0);

  const processSimplePayment = (value: string) => {
    dispatch(
      addPayment({
        newOrderPayment: {
          saveShippingAsBilling: 1,
          paymentMethod: {
            paymentMethodID: value,
          },
        },
      }) as any
    );
  };

  useEffect(() => {
    const nonPrefixPo = removePoPrefix(poPrefix, purchaseOrderNumber);
    if (!poPrefix || !nonPrefixPo) return setPoNumber("");
    setPoNumber(nonPrefixPo);
  }, [poPrefix, purchaseOrderNumber]);

  useEffect(() => {
    if (
      paymentMethod &&
      paymentMethod.paymentMethodID &&
      paymentMethodOnOrder !== paymentMethod.paymentMethodID
    ) {
      setPaymentMethodOnOrder(paymentMethod);
      setSelectedPaymentMethod(paymentMethod);
      setEditExistingPayment(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod]);

  return (
    <Overlay
      active={isFetching}
      styles={{
        overlay: (base: CSSProperties) => ({
          ...base,
          background: "rgba(0, 0, 0, 0)",
        }),
        spinner: (base: CSSProperties) => ({
          ...base,
          width: "100px",
          "& svg circle": {
            stroke: "rgba(211, 211, 211)",
          },
        }),
      }}
      spinner
    >
      <h4>Please add a PO Number</h4>
      <div className='d-flex align-items-center gap-2'>
        <label className='fs-6' htmlFor='poNumber'>
          {poPrefix}
        </label>
        <input
          type='text'
          value={poNumber}
          onChange={({ target: { value } }) => setPoNumber(value)}
          placeholder='PO Number'
          className='my-3 form-control w-25'
          id='poNumber'
          disabled={!!purchaseOrderNumber}
          maxLength={10}
        />
      </div>

      {/* <!-- Payment Method --> */}

      {poNumber ? (
        <>
          <PaymentList
            payments={allPayments}
            disableInteraction={disableInteraction}
            //@ts-ignore
            onRemovePayment={(paymentSelection: OrderPayment) => {
              dispatch(
                removeOrderPayment({ params: paymentSelection }) as any
              ).then((response: any) => {
                if (
                  response.isSuccess() &&
                  Object.keys(response.success()?.errors || {}).length
                )
                  toast.error(getErrorMessage(response.success().errors));
              });
            }}
            resetSelection={() => {
              setPaymentMethodOnOrder("");
              setSelectedPaymentMethod("");
              setEditPaymentDetails(false);
              setEditExistingPayment(false);
            }}
            //@ts-ignore
            onEditDetails={(paymentSelection) => {
              setEditPaymentDetails(true);
              dispatch(
                removeOrderPayment({ params: paymentSelection }) as any
              ).then((response: any) => {
                if (
                  response.isSuccess() &&
                  Object.keys(response.success()?.errors || {}).length
                ) {
                  toast.error(getErrorMessage(response.success().errors));
                } else {
                  setPaymentMethodOnOrder(creditCardPaymentObject.value);
                  setSelectedPaymentMethod(creditCardPaymentObject);
                  setEditExistingPayment(true);
                }
              });
            }}
          />
          {(allPayments.length === 0 || editPaymentDetails) && (
            <>
              <div className='row mb-3'>
                <div className='col-sm-12'>
                  {eligiblePaymentMethodDetails.length === 0 && (
                    <div className='alert alert-warning' role='alert'>
                      {t("frontend.checkout.noPaymentEnabled")}
                    </div>
                  )}
                  {eligiblePaymentMethodDetails.length > 0 && (
                    <SwRadioSelect
                      label={t("frontend.checkout.payment.select")}
                      options={eligiblePaymentMethodDetails}
                      errorMsg={""}
                      onChange={(value: string) => {
                        const foundPaymentMethod = getPaymentMethodByIDFromList(
                          eligiblePaymentMethodDetails,
                          value
                        );
                        setSelectedPaymentMethod(foundPaymentMethod);
                        if (
                          foundPaymentMethod?.paymentMethodType ===
                            CASH_PAYMENT_CODE ||
                          foundPaymentMethod?.paymentMethodType ===
                            CHECK_PAYMENT_CODE
                        ) {
                          processSimplePayment(value);
                        }
                      }}
                      selectedValue={
                        selectedPaymentMethod?.paymentMethodID?.length
                          ? selectedPaymentMethod.paymentMethodID
                          : paymentMethodOnOrder
                      }
                    />
                  )}
                </div>
              </div>
              {selectedPaymentMethod?.paymentMethodType ===
                CREDIT_CARD_CODE && (
                //@ts-ignore
                <CreditCardPayment
                  isEdit={isEditExistingPayment}
                  method={selectedPaymentMethod.paymentMethodID}
                  fulfillment={fulfillment}
                  resetPaymentDetailsSelection={() => {
                    setEditPaymentDetails(false);
                    setEditExistingPayment(false);
                  }}
                  poNumber={formatPoNumber(poPrefix, poNumber)}
                />
              )}
              {selectedPaymentMethod?.paymentMethodType === GIFT_CARD_CODE && (
                <GiftCardPayment
                  method={selectedPaymentMethod.paymentMethodID}
                />
              )}
              {selectedPaymentMethod?.paymentMethodType ===
                TERM_PAYMENT_CODE && (
                //@ts-ignore
                <TermPayment
                  method={selectedPaymentMethod.paymentMethodID}
                  fulfillment={fulfillment}
                  poNumber={formatPoNumber(poPrefix, poNumber)}
                />
              )}
              {selectedPaymentMethod?.paymentMethodType ===
                EXTERNAL_PAYMENT_CODE &&
                selectedPaymentMethod.paymentIntegration.integrationPackage ===
                  PAYPAL_COMMERCE_CODE && (
                  <PayPalCommercePayment
                    method={selectedPaymentMethod.paymentMethodID}
                    cartState={cartState}
                  />
                )}
              {selectedPaymentMethod?.paymentMethodType ===
                EXTERNAL_PAYMENT_CODE &&
                selectedPaymentMethod.paymentIntegration.integrationPackage ===
                  PAYPAL_PAYMENT_CODE && <PayPalPayment />}
              {selectedPaymentMethod?.paymentMethodType ===
                EXTERNAL_PAYMENT_CODE &&
                selectedPaymentMethod.paymentIntegration.integrationPackage ===
                  "tillpayments" && (
                  //@ts-ignore
                  <TillPayments
                    method={selectedPaymentMethod.paymentMethodID}
                  />
                )}
            </>
          )}

          <SlideNavigation
            currentStep={currentStep}
            nextActive={!orderRequirementsList.includes("payment")}
          />
        </>
      ) : null}
    </Overlay>
  );
};

export { PaymentSlide };
