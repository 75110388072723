import { useElementContext } from "@ultracommerce/react-storefront/global";
import AccountAddresses from "./components/AccountAddresses/AccountAddresses";
import { AccountPaymentMethods } from "./components/AccountPaymentMethods/AccountPaymentMethods";
import { AccountProfile } from "./components/AccountProfile/AccountProfile";

export const AccountInformation = () => {
  const {
    CommonModule: { AccountLayout },
  } = useElementContext();

  return (
    <AccountLayout>
      <AccountProfile />
      <AccountAddresses />
      <AccountPaymentMethods />
    </AccountLayout>
  );
};
