import styles from "./AccountAddresses.module.css";
import { Link, useLocation } from "react-router-dom";
import { AccountField } from "../AccountField/AccountField";
import { useToggle } from "../../../common/hooks/useToggle";
import { WarningPopup } from "../../../common/WarningPopup/WarningPopup";
import { useAccountAddresses } from "../../../common/hooks/useAccountAddresses";

const AccountAddresses = () => {
  const { shippingAddresses, billingAddress, onDeleteAccountAddress } =
    useAccountAddresses();

  const { pathname } = useLocation();

  const {
    toggleOff: closePopup,
    isToggledOn: isPopupOPen,
    toggleOn: openPopup,
  } = useToggle();

  return (
    <div className='bg-white mt-5 p-4'>
      <div className='d-flex justify-content-between align-items-center'>
        <h2 className='pt-0'>Addresses</h2>

        <Link
          className='btn btn-secondary rounded-0 d-flex align-items-center gap-2 px-2'
          to='/my-account/addresses/new'
        >
          <i className='bi bi-plus'></i>
          Add Shipping Address
        </Link>
      </div>

      <div className={`${styles["addresses-grid-display"]} my-4`}>
        {billingAddress ? (
          <div className='p-4 border bg-light'>
            <div className='d-flex justify-content-between align-items-center mb-2'>
              <h4 className='pt-0 font-weight-bold fs-5'>
                {billingAddress.accountAddressName}
              </h4>
              <Link to='/my-account/request-cba' state={{ from: pathname }}>
                Request To Change
              </Link>
            </div>
            <AccountField title='Name' value={billingAddress.name} />
            <AccountField
              title='Street'
              value={billingAddress.streetAddress}
            />{" "}
            <AccountField
              title='State And Postal Code'
              value={`${billingAddress.stateCode}, ${billingAddress.postalCode}`}
            />
            <AccountField title='Country' value={billingAddress.countryCode} />
            <AccountField
              className='mt-2'
              title='Phone Number'
              value={billingAddress.phoneNumber}
            />
            <AccountField title='Email' value={billingAddress.emailAddress} />
          </div>
        ) : null}

        {shippingAddresses.length
          ? shippingAddresses.map(
              ({
                name,
                streetAddress,
                stateCode,
                postalCode,
                countryCode,
                phoneNumber,
                emailAddress,
                addressID,
                accountAddressName,
                accountAddressID,
              }) => (
                <div className='p-4 border' key={addressID}>
                  <div className='d-flex justify-content-between align-items-center'>
                    <h4 className='pt-0 font-weight-bold fs-5 mb-2'>
                      {accountAddressName}
                    </h4>
                    <div className='d-flex align-items-center gap-2'>
                      <button
                        className='border-0 bg-transparent'
                        onClick={openPopup}
                      >
                        <i className='bi bi-trash cursor-pointer'></i>
                      </button>
                      <Link to={`/my-account/addresses/${addressID}`}>
                        Edit
                      </Link>
                    </div>
                  </div>
                  <ul className='list-group list-group-flush'>
                    <AccountField title='Name' value={name} />
                    <AccountField title='Street' value={streetAddress} />{" "}
                    <AccountField
                      title='State And Postal Code'
                      value={`${stateCode}, ${postalCode}`}
                    />
                    <AccountField title='Country' value={countryCode} />
                    <AccountField
                      title='Phone Number'
                      className='mt-2'
                      value={phoneNumber}
                    />
                    <AccountField title='Email' value={emailAddress} />
                  </ul>
                  <WarningPopup
                    closePopup={closePopup}
                    action={() => onDeleteAccountAddress(accountAddressID)}
                    isOpen={isPopupOPen}
                    title='Are you sure you want to delete this account address?'
                  />
                </div>
              )
            )
          : null}
      </div>
    </div>
  );
};

export default AccountAddresses;
