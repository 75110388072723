import { DownloadLink } from "../../components/DownloadLink/DownloadLink";

export const DownloadProductInformation = () => (
  <div>
    <h2>Download Product Information</h2>
    <DownloadLink
      href='https://assets.dreamline.com/dreamline-inventory-feed.csv'
      label='Inventory Feed'
      target='_blank'
    />
    <DownloadLink
      href='https://assets.dreamline.com/Dreamline%20Images.zip'
      label='Download All Images'
      target='_blank'
    />
    <DownloadLink
      href='https://assets.dreamline.com/Dreamline%20Installation%20Guides.zip'
      label='Download All Installation Guides'
      target='_blank'
    />
    <DownloadLink
      href='https://assets.dreamline.com/Dreamline%20Technical%20Drawings.zip'
      label='Download All Technical Drawings'
      target='_blank'
    />
  </div>
);
