import {
  addMultipleItemsToOrder,
  getErrorMessage,
} from "@ultracommerce/react-storefront/global";
import { toast } from "react-toastify";
import { Dispatch } from "redux";

type Props = {
  skuIDs: string;
  quantities: string;
};

export const addMultipleItemsToCart = ({ quantities, skuIDs }: Props) => {
  return async (dispatch: Dispatch) => {
    const response = await dispatch(
      addMultipleItemsToOrder({
        params: {
          skuIDs,
          quantities,
        },
      }) as any
    );
    if (
      response.isSuccess() &&
      Object.keys(response.success()?.errors || {}).length
    )
      toast.error(getErrorMessage(response.success().errors));
    if (response.isSuccess()) toast.success("Added to Cart");
    if (!response.isSuccess()) toast.error("Error");
    return response;
  };
};
