import { useGetAllOrders } from "@ultracommerce/react-storefront/global";
import { useState } from "react";
import { PAGE_SIZE } from "../consts";
import { Order } from "@/types/order";
import { useDebounce } from "react-use";
import { Request } from "./types";
import { useSelector } from "react-redux";
import { RootStateType } from "@/types/state";
import { getPaymentMethod } from "../../common/utils/paymentMethods";

const SEARCH_ORDERS_DELAY = 1000;

export const useOrders = () => {
  const [pageIndex, setPageIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { eligiblePaymentMethods } = useSelector(
    (state: RootStateType) => state.userReducer
  );

  const paymentMethod = getPaymentMethod(eligiblePaymentMethods) || "";

  const [request, setRequest] = useGetAllOrders();

  const updatePageIndex = (pageIndex: number) => setPageIndex(pageIndex);
  const updateSearchTerm = (searchTerm: string) => setSearchTerm(searchTerm);

  useDebounce(
    () => {
      // @ts-ignore
      setRequest((request: Request) => ({
        ...request,
        isFetching: true,
        isLoaded: false,
        makeRequest: true,
        params: {
          currentPage: pageIndex + 1, // yup, API is 1 based, not 0
          pageRecordsShow: PAGE_SIZE,
          keyword: searchTerm,
        },
      }));
    },
    SEARCH_ORDERS_DELAY,
    [setRequest, pageIndex, searchTerm]
  );

  // @ts-ignore
  const response = request as Request;
  const data = response.data;
  const orders = data.ordersOnAccount as Order[];

  return {
    orders: orders?.map((order) => ({
      ...order,
      reorderLabel: "Reorder",
      paymentMethod,
    })),
    pagination: {
      pageIndex,
      pageSize: PAGE_SIZE,
      pageCount: Math.ceil(data.records / PAGE_SIZE),
      totalItems: data.records,
    },
    searchTerm,
    updatePageIndex,
    updateSearchTerm,
    isLoaded: response.isLoaded,
  };
};
