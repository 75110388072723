type Props = {
  stepIndex: number;
  currentStepIndex: number;
};

export const getStepCss = ({ stepIndex, currentStepIndex }: Props) => {
  if (stepIndex < currentStepIndex) return "";

  if (stepIndex === currentStepIndex) return "active current";
  return "disabled";
};
