import { getAddresses } from "../../common/utils/getAddresses";
import { UserAccount } from "../../../types/userAccount";
import { AccountInformation } from "../AccountInformation.types";

export const mapAccount = (account: UserAccount): AccountInformation => {
  const { billingAddress } = getAddresses({
    addresses: account.accountAddresses,
    primaryAddressID: account.primaryAddress?.accountAddressID,
  });

  return {
    firstname: account.firstName,
    lastname: account.lastName,
    customerId: account.customerCode,
    salesforeId: account.salesforceCode,
    website: account.website,
    email: account.userName,
    billingEmail: billingAddress?.emailAddress || "",
    company: account.company,
    communiactionPreferences: !!account.emailConsent,
  };
};
