import {
  useElementContext,
  useTermPayment,
} from "@ultracommerce/react-storefront/global";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  method: any;
  fulfillment: any;
  isQuote: boolean;
  orderID: string;
  updateQuote: (quote: any) => any;
  poNumber: string;
};

const TermPayment = ({
  method,
  fulfillment,
  isQuote = false,
  orderID,
  updateQuote,
  poNumber,
}: Props) => {
  const {
    CommonModule: { PaymentAddressSelector, Button },
  } = useElementContext();
  const { t } = useTranslation();
  const selectedAccountID = "";
  const [saveShippingAsBilling, setSaveShippingAsBilling] = useState<
    string | boolean
  >("");

  const {
    onPaymentAddressSelect,
    onPaymentAddressSave,
    onSaveShippingAsBilling,
    fulfillmentMethodType,
    accountAddressID,
    setTermOrderNumber,
    termOrderNumber,
  } = useTermPayment({ method, fulfillment, isQuote, orderID });

  useEffect(() => {
    setTermOrderNumber(poNumber);
  }, [poNumber, setTermOrderNumber]);

  return (
    <>
      <div className='row mb-2'>
        {fulfillmentMethodType === "shipping" && (
          <div className='col-sm-12 mt-2'>
            <div className='form-group mb-0'>
              <div className='custom-control custom-checkbox'>
                <input
                  className='custom-control-input'
                  type='checkbox'
                  id='saveShippingAsBilling'
                  checked={!!saveShippingAsBilling}
                  onChange={() =>
                    setSaveShippingAsBilling(!saveShippingAsBilling)
                  }
                />
                <label
                  className='custom-control-label ms-1'
                  htmlFor='saveShippingAsBilling'
                >
                  Billing address same as shipping address
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
      {saveShippingAsBilling && termOrderNumber?.length > 0 && (
        <Button
          label='Submit'
          onClick={(e) => {
            onSaveShippingAsBilling(e).then((response: any) => {
              if (response.isSuccess() && isQuote && response.success().quote)
                updateQuote(response.success().quote);
            });
          }}
          classList='btn btn-primary mb-3'
        />
      )}
      {!saveShippingAsBilling && termOrderNumber?.length > 0 && (
        //@ts-ignore
        <PaymentAddressSelector
          addressTitle={"Billing Address"}
          selectedAccountID={selectedAccountID || accountAddressID}
          onSelect={(e: ChangeEvent<HTMLSelectElement>) => {
            onPaymentAddressSelect(e).then((response: any) => {
              if (response.isSuccess() && isQuote && response.success().quote)
                updateQuote(response.success().quote);
            });
          }}
          onSave={(e: any): any => {
            onPaymentAddressSave(e).then((response: any) => {
              if (response.isSuccess() && isQuote && response.success().quote)
                updateQuote(response.success().quote);
            });
          }}
        />
      )}
    </>
  );
};
export { TermPayment };
