import React from "react";
// import PropTypes from 'prop-types'
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PaymentMethodItem } from "../PaymentMethodItem/PaymentMethodItem";
import { RootStateType } from "@/types/state";
import { AccountPaymentMethod } from "@/types/payments";
import styles from "./AccountPaymentMethods.module.css";

const PAYMENT_METHODS = {
  CREDIT_CARD: "creditCard",
  PAY_BY_TERM: "payByTerm30Days",
};

const AccountPaymentMethods = () => {
  const { accountPaymentMethods, eligiblePaymentMethods } = useSelector(
    (state: RootStateType) => state.userReducer
  );

  if (eligiblePaymentMethods !== PAYMENT_METHODS.CREDIT_CARD) return null;

  return (
    <div className='bg-white p-4 mt-5'>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <h2 className='pt-0 font-weight-bold'>Payment Information</h2>
        <Link
          className='btn btn-secondary rounded-0 d-flex align-items-center gap-2 px-2'
          to='/my-account/cards/new'
        >
          <i className='bi bi-plus'></i>
          Add New Card
        </Link>
      </div>

      <div className={styles["payments-grid-display"]}>
        {accountPaymentMethods.map((card: AccountPaymentMethod) => (
          <PaymentMethodItem key={card.accountPaymentMethodName} {...card} />
        ))}
      </div>
    </div>
  );
};

export { AccountPaymentMethods };
