import { Fragment, ReactNode } from "react";

import {
  flexRender,
  Table as TableType,
  PaginationState as TanstackPaginationState,
} from "@tanstack/react-table";
import { getPaginationLabel } from "./utils";

export type PaginationState = TanstackPaginationState & { totalItems: number };

export const Table = <RowData,>({
  table,
  renderExtended,
}: {
  table: TableType<RowData>;
  renderExtended?: (rowData: RowData) => ReactNode;
}) => (
  <table className='table mt-3 border-top border-dark'>
    <thead>
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <th key={header.id} className='py-3'>
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
            </th>
          ))}
        </tr>
      ))}
    </thead>
    <tbody>
      {table.getRowModel().rows.map((row) => {
        const cells = row.getVisibleCells();
        const isExpanded = row.getIsExpanded();

        return (
          <Fragment key={row.id}>
            <tr className={`${isExpanded ? "bg-light" : "bg-white"}`}>
              {cells.map((cell) => {
                const className = getCellClassName(
                  // @ts-ignore
                  cell.column.columnDef.meta?.className,
                  isExpanded
                );

                return (
                  <td key={cell.id} className={className}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
            {isExpanded && renderExtended && (
              <tr className='bg-light'>
                <td colSpan={cells.length}>
                  <div className='bg-white border border-dark m-2 mt-0'>
                    {renderExtended(row.original)}
                  </div>
                </td>
              </tr>
            )}
          </Fragment>
        );
      })}
    </tbody>
  </table>
);

export const TablePagination = <RowData,>({
  table,
  className,
}: {
  table: TableType<RowData>;
  className?: string;
}) => {
  const pagination = table.getState().pagination as PaginationState;
  const count = table.getPageCount();

  return (
    <nav
      className={`d-flex justify-content-between align-items-center ${className}`}
    >
      <p>{getPaginationLabel(pagination)}</p>

      {count < 2 ? null : (
        <ul className='pagination justify-content-center gap-4'>
          <li className='page-item'>
            <button
              className={`bg-transparent border-0 text-decoration-none ${
                table.getCanPreviousPage() && "disabled"
              }`}
              aria-label='Previous'
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <span aria-hidden='true'>
                <i className='bi bi-chevron-left'></i>
                Previous
              </span>
            </button>
          </li>

          <li className='page-item'>
            <button
              className={`bg-transparent border-0 text-decoration-none ${
                !table.getCanNextPage() && "disabled"
              }`}
              aria-label='Next'
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <span aria-hidden='true'>
                Next
                <i className='bi bi-chevron-right'></i>
              </span>
            </button>
          </li>
        </ul>
      )}
    </nav>
  );
};

const getCellClassName = (metaClassName = "", isCellExpanded: boolean) =>
  [metaClassName, isCellExpanded && "border-bottom-0", "py-3"]
    .filter(Boolean)
    .join(" ");
