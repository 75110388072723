import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  useElementContext,
  useGetAllOrders,
} from "@ultracommerce/react-storefront/global";
import { useEffect } from "react";

const OrderConfirmation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    PageModule: { DynamicPage },
  } = useElementContext();

  const [orders, setRequest] = useGetAllOrders() as any;

  useEffect(() => {
    if (!orders.isFetching && !orders.isLoaded) {
      //@ts-ignore
      setRequest(() => ({
        ...orders,
        isFetching: true,
        isLoaded: false,
        params: { pageRecordsShow: 1, currentPage: 1 },
        makeRequest: true,
      }));
    }
  }, [orders, setRequest]);

  if (!orders) return;

  return (
    <DynamicPage ignoreLayout={true}>
      <div className='bg-light p-0'>
        <div>
          <div className='bg-light p-4 text-center'>
            <h2>{t("frontend.order.thank_you")}</h2>
            {orders.data &&
              orders.data.ordersOnAccount &&
              orders.data.ordersOnAccount.length > 0 && (
                <>
                  <h2>
                    {`${t("frontend.order.key")} `}
                    {`#${orders.data.ordersOnAccount?.at(0).orderNumber}`}{" "}
                  </h2>
                  <h3 className='pt-2 font-weight-bold mb-0'>
                    PO:{" "}
                    {
                      orders.data.ordersOnAccount?.at(0)
                        .orderPayments_purchaseOrderNumber
                    }
                  </h3>
                </>
              )}
          </div>

          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col col-md-4'>
                <button
                  className='btn btn-link w-100 mb-3 align-center link-btn'
                  onClick={() => navigate("/my-account/overview")}
                >
                  {t("frontend.header.myAccount")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DynamicPage>
  );
};

export default OrderConfirmation;
