import { useMemo } from "react";
import {
  useContentContext,
  useElementContext,
} from "@ultracommerce/react-storefront/global";
// @ts-ignore
import { dynamicAreaLayoutEngine } from "@ultracommerce/react-storefront/global/src/services/factory/Factory_Content_LayoutEngine.class.js";

import { useComponentData } from "./RepDashboard.componentService";
import { RepDashboardComponentView } from "./RepDashboard.componentView";

const RepDashboardComponent = () => {
  const {
    CommonModule: { AccountLayout },
  } = useElementContext();
  const { componentData } = useComponentData();

  return (
    <AccountLayout>
      <RepDashboardComponentView componentData={componentData} />

      <DynamicContentSlot />
    </AccountLayout>
  );
};

const DynamicContentSlot = () => {
  const {
    CommonModule: { DynamicContent },
  } = useElementContext();
  const {
    templateData: { overviewDynamicContent },
  } = useContentContext();

  const contentElements = useMemo(
    () =>
      new dynamicAreaLayoutEngine().processPageComponents(
        overviewDynamicContent || []
      ),
    [overviewDynamicContent]
  );

  return <DynamicContent contentElements={contentElements} />;
};

export default RepDashboardComponent;
